import React, { useEffect } from "react";
import styled from "styled-components";
import outline from "../assets/outline.svg";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "./Navbar.jsx";
import { Button, Dropdown, Space } from "antd";
import Product_Cart from "./Product_Cart";
import { useGlobalContext } from "../context/context";
import CheckoutNav from "./CheckoutNav";
import { useState } from "react";
import Swal from "sweetalert2";
import { isMobile } from "react-device-detect";
import ContinueBackBTN from "../components/Reuseable Components/ContinueBackButton";
import logoBlack from "../assets/logo-black.png";
import right_arrow from "../assets/right_arrow.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import config from "../config.js";
import axios from "axios";
import { StaticHeader } from "./StaticHeader/StaticHeader.jsx";
import Footer from "./Footer/Footer.jsx";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../redux/slice/loaderSlice.js";

const initialValues = {
  ShippingToName: "",
  AddressLine1: "",
  AddressLine2: "",
  ZipCode: "",
  City: "",
  State: "",
  Organization: "",
  PhoneNumber: "",
  ShippingToEmail: "",
  IsShippingInfoSaved: false,
};

function Shipping() {
  const items = [
    {
      key: "1",
      label: (
        <a target="_blank" rel="noopener noreferrer">
          +1
        </a>
      ),
    },
  ];
  const { cart, getpath } = useGlobalContext();

  const cartDetails = useSelector(state => state.cart)
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState([]);
  const [user, setUser] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [saleTax, setSaleTax] = useState([]);
  const [totalAmmount, setTotalAmmount] = useState(0);
  const [orderData, setOrderData] = useState(null);
  const validationSchema = Yup.object().shape({
    ShippingToName: Yup.string().required("Name is required"),
    AddressLine1: Yup.string().required("Address Line 1 is required"),
    AddressLine2: Yup.string(),
    ZipCode: Yup.string()
      .required("Zip Code is required")
    // .matches(/^\d{5}$/, "Invalid Zip Code")
    ,
    City: Yup.string().required("City is required"),
    State: Yup.string().required("State is required"),
    Organization: Yup.string().required("Organization is required"),
    PhoneNumber: Yup.string()
      .required("Phone Number is required")
      .matches(/^\d{10}$/, "Invalid Phone Number"),
    ShippingToEmail: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
  });
  const dispatch = useDispatch()
  let linkval = "/Shipping";
  let objval = { haider: "Shipping" };

  const getData = (linkval, objval) => {
    navigate("/Shipping", { state: { objval } });
  };



  useEffect(() => {
    const TotalAmount = localStorage.getItem("TotalAmount1");
    const finalAmmount = localStorage.getItem("total");
    setTotalAmmount(finalAmmount);
    const tempCart = localStorage.getItem("tempCart");
    const PersistedCart = JSON.parse(tempCart);

    if (TotalAmount > 0) {
      const itemsToStore = PersistedCart?.filter(
        (item) =>
          (item.id === 2 || item.id === 1 || item.id === 3 || item.id === 4 || item.id === 5) && item.amount > 0
      );
      setCartData(itemsToStore);
    }
  }, []);

  useEffect(() => {
    let total = 0;
    cartDetails?.map((x) => {
      total += x.Products.Amount * x.Quantity
    })
    setTotalAmmount(total);
  }, [cartDetails])
  


  useEffect(() => {
    const userDataByAccounts = JSON.parse(localStorage.getItem('socialObj'));
    const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
    if (userDataByAccounts) {
      const Name = userDataByAccounts.FirstName + ' ' + userDataByAccounts.LastName;
      setUserName(Name);
      setUser(userDataByAccounts)
    } else if (userDataByEmail) {
      setUserName(userDataByEmail.Data.UserName);
      setUser(userDataByEmail.Data)
    } else {
      setUserName(null);
    }

    const idByGoogle = JSON.parse(localStorage.getItem('social_login_ID'));
    if (idByGoogle) {
      setUser(idByGoogle)
    }

    const saleTax = localStorage.getItem("saleTax");
    if (saleTax) {
      setSaleTax(saleTax)
    }
  }, []);

  useEffect(() => {
    if (user) {
      getOrderData();
    }
  }, [user]);

  const getOrderData = async (event) => {
    setLoading(true)
    dispatch(showLoader(true))
    try {
      const obj = {
        Url: "/get-last-order",
        UserId: user.UserId ? user.UserId : user,
      };
      let api = `${config.BASE_URL}`
      const response = await axios.post(
        api, obj
      );
      setOrderData(response.data?.OrderDetailModelList[0])
      setLoading(false)
      dispatch(showLoader(false))
    } catch (error) {
      // console.error('Error updating profile:', error);
      setLoading(false)
      dispatch(showLoader(false))
    }
  };

  useEffect(() => {
    if (orderData?.IsShippingInfoSaved === true) {
      formik.setValues({
        ShippingToName: orderData?.ShippingToName,
        AddressLine1: orderData?.AddressLine,
        AddressLine2: orderData?.AddressLine2 || '',
        ZipCode: orderData?.ZipCode,
        City: orderData?.City,
        State: orderData?.State,
        Organization: orderData?.Organization,
        PhoneNumber: orderData?.PhoneNumber,
        ShippingToEmail: orderData?.ShippingToEmail,
        IsShippingInfoSaved: orderData?.IsShippingInfoSaved,
      });
    }
  }, [orderData]);

  const SaveUser = async (values) => {
     if(cartDetails.length == 0){
        Swal.fire({
          icon: 'warning',
          title: "Cart Products",
          text: "No any product in cart",
          showCancelButton: false,
          confirmButtonText: 'Try Again',
        })
        return;
      }

    if (formik.isValid) {
      setLoad(true);
      const orderItemList = cartDetails.map((item) => ({
        ProductId: item.Products.Id,
        Qty: item.Quantity,
        Price: item.Products.Amount.toFixed(2) * item.Quantity,
        name: item.ProductName,
      }));

      const shippingData = {
        Url: "/checkout",
        Order: {
          UserId: user.UserId ? user.UserId : user,
          ShippingToName: values.ShippingToName,
          AddressLine1: values.AddressLine1,
          AddressLine2: values.AddressLine2,
          ZipCode: values.ZipCode,
          City: values.City,
          State: values.State,
          Organization: values.Organization,
          PhoneNumber: values.PhoneNumber.toString(),
          ShippingToEmail: values.ShippingToEmail,
          ShippingFee: 10,
          SaleTax: 0,
          DeliveryType: "Online Payment",
          OrderItemList: orderItemList,
          IsShippingInfoSaved: values?.IsShippingInfoSaved,
          ZohoCustomerId: values?.ZohoCustomerId,
          // [
          //   {
          //     ProductId: orderItemList[0]?.ProductId,
          //     Qty: orderItemList[0]?.Qty,
          //     Price: orderItemList[0]?.Price,
          //     name: orderItemList[0]?.name,
          //   }
          // ]
        }
      }
      let api = `${config.BASE_URL}`
      const response = await axios.post(api, shippingData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      navigate("/payment", { state: {...orderData, ...shippingData.Order} })
      setLoad(false);

    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Invalid Data',
        text: "Invalid Details Added, Please Enter Again!",
        showCancelButton: false,
        confirmButtonText: 'Try Again!',
      })
    }
  }


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: SaveUser,
  });

  const handleCheckboxChange = (e) => {
    formik.setFieldValue("IsShippingInfoSaved", e.target.checked);
  };
  return (
    <>
      <>
        <body className="renaware">
          {/* {loading ? (
            <div className="w-100 page_loader align-items-center d-flex justify-content-center">
              <h1
                class="loader"
                style={{
                  // color: "#101A36",
                  margin: "0",
                  width: '30px',
                  height: '30px',
                  backdropFilter: "blur(8px)",
                }}
              ></h1>
            </div>
          ) : (
            ""
          )} */}
          <StaticHeader />

          <section className="login_page inner-banner-section sign_up_outer d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div
                  class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                  <ul class="card_breadcrumb">
                    <li
                      onClick={() => navigate('/cart')}
                    >
                      <a >
                        Cart
                      </a>
                    </li>
                    <li class="active" onClick={() => navigate('/Shipping')}>
                      <a >
                        Shipping
                      </a>
                    </li>
                    <li
                    // onClick={() => navigate('/Payment')}
                    >
                      <a>
                        Checkout
                      </a>
                    </li>
                    <button
                      class="btn btn-outline-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none" onClick={() => navigate("/productForResearchers")}>Cancel
                      order</button>
                    <button style={{ width: '100%', maxWidth: '229px', minWidth: '229px' }}
                      class="btn btn-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none" onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}>
                      {load ? (
                        <div className='w-100 align-items-center d-flex justify-content-center'>
                          <h1
                            class="loader"
                            disabled={load}
                            style={{
                              // color: "#101A36",
                              margin: "-5px",
                              width: '30px',
                              height: '30px',
                            }}
                          ></h1>
                        </div>
                      ) : (
                        "Proceed to Checkout"
                      )}
                    </button>

                  </ul>
                </div>
              </div>
              <div class="row my-4 py-lg-2">
                <div class="col-12">
                  <div class="card_title">
                    <h6 class="m-0"><b
                      class="d-flex align-items-center gap-4">
                      <img src={right_arrow}
                        class="d-lg-block d-md-block d-none"
                        onClick={() => navigate('/cart')}
                        alt="" />
                      Shipping details
                    </b></h6>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 pe-lg-5">
                  <div class="form_feild card_title">
                    <form action>
                      <div class="row gx-lg-5 gx-3">
                        <div
                          class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                          <label for class="w-100">Zip code
                            :</label>
                          <input type="text"
                            className={`form-control ${formik.touched.ZipCode && formik.errors.ZipCode
                              ? "is-invalid"
                              : ""
                              }`}
                            name="ZipCode"
                            placeholder="Zip Code"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.ZipCode}
                            isValid={formik.isValid}
                            isTouched={formik.touched.ZipCode}
                            invalidFeedback={formik.errors.ZipCode} />
                          <div className="invalid-feedback">
                            {formik.errors.ZipCode}
                          </div>

                        </div>
                        <div
                          class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                          <label for class="w-100">State :</label>
                          <input type="text"
                            className={`form-control ${formik.touched.State && formik.errors.State
                              ? "is-invalid"
                              : ""
                              }`}
                            name="State"
                            placeholder="State"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.State}
                            isValid={formik.isValid}
                            isTouched={formik.touched.State}
                            invalidFeedback={formik.errors.State} />
                          <div className="invalid-feedback">
                            {formik.errors.State}
                          </div>

                        </div>
                        <div
                          class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                          <label for class="w-100">Address
                            :</label>
                          <input type="text"
                            className={`form-control ${formik.touched.AddressLine1 && formik.errors.AddressLine1
                              ? "is-invalid"
                              : ""
                              }`}
                            name="AddressLine1"
                            placeholder="Address"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.AddressLine1}
                            isValid={formik.isValid}
                            isTouched={formik.touched.AddressLine1}
                            invalidFeedback={formik.errors.AddressLine1} />
                          <div className="invalid-feedback">
                            {formik.errors.AddressLine1}
                          </div>

                          <input type="text"
                            className={`form-control mt-2 ${formik.touched.AddressLine2 && formik.errors.AddressLine2
                              ? "is-invalid"
                              : ""
                              }`}
                            name="AddressLine2"
                            placeholder="Address"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.AddressLine2}
                            isValid={formik.isValid}
                            isTouched={formik.touched.AddressLine2}
                            invalidFeedback={formik.errors.AddressLine2} />
                          <div className="invalid-feedback">
                            {formik.errors.AddressLine2}
                          </div>
                        </div>
                        <div
                          class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                          <label for class="w-100">City :</label>
                          <input type="text"
                            className={`form-control ${formik.touched.City && formik.errors.City
                              ? "is-invalid"
                              : ""
                              }`}
                            name="City"
                            placeholder="City"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.City}
                            isValid={formik.isValid}
                            isTouched={formik.touched.City}
                            invalidFeedback={formik.errors.City} />
                          <div className="invalid-feedback">
                            {formik.errors.City}
                          </div>
                        </div>
                        <div class="col-12 pt-2 mb-4">
                          <h6 class="m-0"><b>Contact
                            information</b></h6>
                        </div>
                        <div
                          class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                          <label for class="w-100">Ship to
                            :</label>
                          <input type="text"
                            className={`form-control ${formik.touched.ShippingToName && formik.errors.ShippingToName
                              ? "is-invalid"
                              : ""
                              }`}
                            name="ShippingToName"
                            placeholder="Ship To"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.ShippingToName}
                            isValid={formik.isValid}
                            isTouched={formik.touched.ShippingToName}
                            invalidFeedback={formik.errors.ShippingToName} />
                          <div className="invalid-feedback">
                            {formik.errors.ShippingToName}
                          </div>
                        </div>
                        <div
                          class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                          <label for class="w-100">Email :</label>
                          <input type="mail"
                            className={`form-control ${formik.touched.ShippingToEmail && formik.errors.ShippingToEmail
                              ? "is-invalid"
                              : ""
                              }`}
                            name="ShippingToEmail"
                            placeholder="Email"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.ShippingToEmail}
                            isValid={formik.isValid}
                            isTouched={formik.touched.ShippingToEmail}
                            invalidFeedback={formik.errors.ShippingToEmail} />
                          <div className="invalid-feedback">
                            {formik.errors.ShippingToEmail}
                          </div>
                          <sup>We will send a confirmation on this
                            e-mail.</sup>
                        </div>
                        <div
                          class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                          <label for class="w-100">Organization
                            :</label>
                          <input type="text"
                            className={`form-control ${formik.touched.Organization && formik.errors.Organization
                              ? "is-invalid"
                              : ""
                              }`}
                            name="Organization"
                            placeholder="Organization"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.Organization}
                            isValid={formik.isValid}
                            isTouched={formik.touched.Organization}
                            invalidFeedback={formik.errors.Organization} />
                          <div className="invalid-feedback">
                            {formik.errors.Organization}
                          </div>
                        </div>
                        <div
                          class="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-4 mb-3">
                          <label for class="w-100">Mobile / Phone
                            :</label>
                          <input type="number"
                            className={`form-control ${formik.touched.PhoneNumber && formik.errors.PhoneNumber
                              ? "is-invalid"
                              : ""
                              }`}
                            name="PhoneNumber"
                            placeholder="Mobile/Phone Number"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.PhoneNumber}
                            isValid={formik.isValid}
                            isTouched={formik.touched.PhoneNumber}
                            invalidFeedback={formik.errors.PhoneNumber} />
                          <div className="invalid-feedback">
                            {formik.errors.PhoneNumber}
                          </div>
                          <sup>Phone numbers shall be used only
                            for delivery purposes.</sup>
                        </div>
                        <div class="col-12 pt-lg-2 pt-lg-2 pt-0 mt-lg-0 mt-md-0 mt-4">
                          <div
                            class="form d-flex align-items-center">
                            <input type="checkbox"
                              name="IsShippingInfoSaved"
                              class="form-check-input m-0"
                              checked={formik.values.IsShippingInfoSaved}
                              onChange={(e) => {
                                formik.handleChange(e);
                                handleCheckboxChange(e);
                              }}
                            />
                            <label for class="ms-3 mb-0 text-start"><p
                              class="m-0 text-start">Save shipping
                              information for the next
                              time.</p></label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 ps-lg-5 d-lg-block d-md-block d-none">
                  <div class="summary_card">
                    <div class="card_title">
                      <h6 className="mb-lg-4 mb-3"><b>Order summary</b></h6>
                      <>
                        {cartDetails.map((item, index) => (
                          <div key={index}>
                            <p className="mb-2 text-start">{item.Products.ProductName}</p>
                            <div className="row">
                              <div className="col-lg-6">
                                <p className="d-flex align-items-center justify-content-between mb-2">Quantity: <span>{item.Quantity}</span></p>
                                <p className="d-flex align-items-center justify-content-between mb-0">Price: <span>${item.Products.Amount.toFixed(2) * item.Quantity}</span></p>
                              </div>
                            </div>
                            {index !== cartDetails.length - 1 && <hr className="my-lg-4" />}
                          </div>
                        ))}
                      </>

                      <hr class="my-lg-4" />
                      <div class="row">
                        <div class="col-lg-6">
                          <p
                            class="d-flex align-items-center justify-content-between mb-1">Shipping
                            : <span>$10.00</span></p>
                        </div>
                      </div>
                      <p class="mb-0 text-start">4-7 business days</p>
                      <hr class="my-lg-3" />
                      <div class="row">
                        <div class="col-lg-6">
                          <p
                            class="d-flex align-items-center justify-content-between mb-1">Total : <span><b>${parseInt(totalAmmount) + 10}</b></span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-lg-3 pt-md-3 pt-2">
                <div class="col-12 mt-4">
                  <button class="btn btn-primary d-lg-none d-md-none d-block w-100" onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }} >
                    {load ? (
                      <div className='w-100 align-items-center d-flex justify-content-center'>
                        <h1
                          class="loader"
                          disabled={load}
                          style={{
                            // color: "#101A36",
                            margin: "-5px",
                            width: '30px',
                            height: '30px',
                          }}
                        ></h1>
                      </div>
                    ) : (
                      "Proceed to Checkout"
                    )}</button>
                  <button class="btn btn-outline-primary mt-lg-0 mt-md-3 mt-3 d-lg-none d-md-none d-block w-100" onClick={() => navigate("/productForResearchers")}>Cancel
                    order</button>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </body>
      </>

    </>
  );
}

export default Shipping;

