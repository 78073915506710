import "./RenAware.css";
import renAwareImg from "../../assets/images/renAware.png";
import { Link } from "react-router-dom";

function RenAware() {
  return (
    <div className="RenAware">
      <div className="row m-0 w-100 h-100">
        <div className="col-12 col-lg-6 h-100">
          <img src={renAwareImg} alt="renAwareImg" />
        </div>
        <div className="col-12 col-lg-6 d-flex flex-column align-items-start justify-content-center gap-24">
          <div className="heading">
            RenAware, your at-Home solution for metabolic insights
          </div>
          <p>
            RenAware offers a convenient solution for tracking metabolic profile
            at home, for convenient and targetted patient recruitment for
            clinical research
          </p>
          <Link to="/productForResearchers"><button>View products for Research</button></Link>
        </div>
      </div>
    </div>
  );
}

export default RenAware;
