import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
    name: "loader",
    initialState: {
        count : 0
    },
    reducers: {
      showLoader: (state, action) => {
          if(action.payload){
              state.count += 1;
            }else{
              if(state.count > 0){
                state.count -= 1;
              }
            }
            return state
        },
    }
})

export const { showLoader } = loaderSlice.actions;

export default loaderSlice.reducer