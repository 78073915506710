import React, { useEffect, useState } from 'react'
import './Products.css'
import medicin1 from '../../assets/images/medicin1.jpg'
import medicin2 from '../../assets/images/medicin2.jpg'
import axios from 'axios'
import ProductComponent from '../ProductComponent/ProductComponent'
import { useSelector } from 'react-redux'

function Products() {

    const products = useSelector((state) => state.products.individualProducts)
    let link = "https://sensify-usermanual.s3.us-east-1.amazonaws.com/User+manual+for+Individuals+-+detailed.pdf";
  return (
    <div className="Products">
        <div className='row m-0'>
        {
            products.map((product,index) => {
                return <ProductComponent product={product} key={index} manual={link} />
            })
        }
        </div>
    </div>
  )
}

export default Products
