import { Fragment } from "react";
import IndividualsBanner from "../../components/InidividualsBanner/IndividualsBanner";
import IndividualsUsed from "../../components/IndividualsUsed/IndividualsUsed";
import TestYourSense from "../../components/TestYourSense/TestYourSense";
import Products from "../../components/Products/Products";
import SmellLossIndividuals from "../../components/SmellLossIndividuals/SmellLossIndividuals";
import PathOfSmell from "../../components/PathOfSmell/PathOfSmell";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Individuals = () => {
  return (
    <Fragment>
      <Header />
      <IndividualsBanner />
      <PathOfSmell />
      <SmellLossIndividuals />
      <IndividualsUsed />
      <Products />
      <TestYourSense />
      <Footer />
    </Fragment>
  );
};

export default Individuals;
