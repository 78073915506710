import React, { Fragment, useEffect } from "react";
import "./Home.css";
import LateDiagnosis from "../../components/LateDiagnosis/LateDiagnosis";
import SilentWarning from "../../components/SilentWarning/SilentWarning";
import MultiModal from "../../components/MultiModal/MultiModal";
import OngoingStudies from "../../components/OngoingStudies/OngoingStudies";
import ResearchPartner from "../../components/ResearchPartner/ResearchPartner";
import SmellLoss from "../../components/SmellLoss/SmellLoss";
import Scentaware from "../../components/Scentaware/Scentaware";
import ScentAwareTestKits from "../../components/ScentAwareTestKits/ScentAwareTestKits";
import ForResearchers from "../../components/ForResearchers/ForResearchers";
import Individuals from "../../components/Individuals/Individuals";
import Revolutionizing from "../../components/Revolutionizing/Revolutionizing";
import ProductVideo from "../../components/ProductVideo/ProductVideo";
import MainBanner from "../../components/MainBanner/MainBanner";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function Home() {
  return (
    <Fragment>
      <Header />
      <div id="about">
        <MainBanner />
      </div>
      <LateDiagnosis />
      <SilentWarning />
      <MultiModal />
      <OngoingStudies />
      <ResearchPartner />
      <div id="smellLoss">
        <SmellLoss />
      </div>
      <div id="products">
        <Scentaware />
      </div>
      <div id="individualsandresearchers">
        <ForResearchers />
      </div>
      <Individuals />
      <Revolutionizing />
      <div id="productVideo">
        <ProductVideo />
      </div>
      <ScentAwareTestKits />
      <Footer />
    </Fragment>
  );
}

export default Home;
