import "./ResearchersBanner.css";
import video from "../../assets/video/bannerVideo.mp4";

function ResearchersBanner() {
  return (
    <div className="ResearchersBanner">
      <video autoPlay loop muted playsInline>
        <source src={video} type="video/mp4" />
      </video>
      <div className="text-content">
        <h1>Developing Real-World Digital Measures to Improve Clinical Research and Care</h1>
        <p>Pioneering novel early detection system for Neurodegenerative Diseases</p>
      </div>
    </div>
  );
}

export default ResearchersBanner;
