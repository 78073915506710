import { createSlice } from "@reduxjs/toolkit";
import config from "../../config";
import axios from "axios";


let api = `${config.BASE_URL}`;
export const getAllProducts = () => (dispatch) => {
    let obj = {
      Url :"/product-details"
    }
    axios
      .post(api, obj)
      .then((res) => {
        let indData = res.data.Product.filter(x => x.Category == "Individual")
        let resData = res.data.Product.filter(x => x.Category == "Research")
        dispatch(setProducts(res.data.Product))
        dispatch(setIndividualProducts(indData))
        dispatch(setResearchProducts(resData))
      })
      .catch((err) => {
        console.error(err);
      });
}

const productSlice = createSlice({
    name: "product",
    initialState: {
        allProducts : [],
        individualProducts: [],
        researchProducts: [],
    },
    reducers: {
        setProducts: (state, action) => {
            state.allProducts = action.payload;
            return state
        },
        setIndividualProducts: (state, action) => {
            state.individualProducts = action.payload;
            return state
        },
        setResearchProducts: (state, action) => {
            state.researchProducts = action.payload;
            return state
        }
    }
})

export const { setProducts, setIndividualProducts, setResearchProducts} = productSlice.actions;

export default productSlice.reducer