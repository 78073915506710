import { Spinner } from "react-bootstrap";
import "./Loader.css";
import { useSelector } from "react-redux";

function Loader() {
  let loader = useSelector((state) => state.loader.count);
  if (loader) {
    return (
      <div className="loader_cotainer">
        <div className="d-flex flex-column text-white">
          <Spinner variant="white" style={{ width: "60px", height: "60px" }} />
          <span>Loading...</span>
        </div>
      </div>
    );
  }
}

export default Loader;
