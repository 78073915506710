import React from "react";
import "./ScentAwareTestKits.css";
import arrowLeft from "../../assets/images/arrow-left.svg";
import arrowRight from "../../assets/images/arrow-right.svg";
import medicin1 from "../../assets/images/medicin1.jpg";
import medicin2 from "../../assets/images/medicin2.jpg";
import medicin3 from "../../assets/images/medicin3.jpg";
import medicin4 from "../../assets/images/medicin4.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

function ScentAwareTestKits() {
  return (
    <div className="ScentAwareTestKits">
      <div className="ScentAwareTestKits_header">
        <div className="ScentAwareTestKits_header_left">
          <h2>ScentAware Test Kits</h2>
          <div className="line"></div>
        </div>
        <div className="ScentAwareTestKits_header_right">
          <button className="custom-prev">
            <img src={arrowLeft} alt="arrowLeft" />
          </button>
          <button className="custom-next">
            <img src={arrowRight} alt="arrowRight" />
          </button>
        </div>
      </div>
      <div className="row m-0 d-lg-none d-flex g-3">
        <div className="col-12 col-md-6 p-lg-0 p-2">
          <Link to="/productForResearchers#Scentaware16">
            <div
              className="ScentAwareTestKits_card"
              style={{ background: `url(${medicin1})` }}
            >
              <div className="content">
                <div className="title">ScentAware-8</div>
                <p>Scentaware 8-scent Smell Test and Training kit</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-12 col-md-6 p-lg-0 p-2">
          <Link to="/productForResearchers#Scentaware16">
            <div
              className="ScentAwareTestKits_card"
              style={{ background: `url(${medicin2})` }}
            >
              <div className="content">
                <div className="title">ScentAware-16</div>
                <p>Scentaware 16-scent Smell Test and Training kit</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-12 col-md-6 p-lg-0 p-2">
          <Link to="/productForResearchers#Scentaware16">
            <div
              className="ScentAwareTestKits_card"
              style={{ background: `url(${medicin3})` }}
            >
              <div className="content">
                <div className="title">ScentAware pro</div>
                <p>Scentaware Pro 16 Smell Test and Training kit</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-12 col-md-6 p-lg-0 p-2">
          <Link to="/productForResearchers#Scentaware16">
            <div
              className="ScentAwareTestKits_card"
              style={{ background: `url(${medicin4})` }}
            >
              <div className="content">
                <div className="title">ScentAware DIY</div>
                <p>Create your own olfactory test.</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="ScentAwareTestKits_slider">
        <Swiper
          spaceBetween={10}
          loop={true}
          navigation={{
            prevEl: ".custom-prev",
            nextEl: ".custom-next",
          }}
          pagination={{
            clickable: true,
            bulletClass: "custom-pagination-bullet",
            bulletActiveClass: "custom-pagination-bullet-active",
            el: ".custom-pagination",
          }}
          freeMode={true}
          modules={[Navigation, Pagination]}
          breakpoints={{
            1920: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1700: {
              slidesPerView: 2.5,
              spaceBetween: 10,
            },
            1500: {
              slidesPerView: 2.3,
              spaceBetween: 10,
            },
            1330: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1060: {
              slidesPerView: 1.5,
              spaceBetween: 10,
            },
            1000: {
              slidesPerView: 1.3,
              spaceBetween: 10,
            },
          }}
          className="d-lg-flex d-none"
        >
          <SwiperSlide className="slide">
            <Link to="/productForResearchers#Scentaware16">
              <div
                className="ScentAwareTestKits_slider_card"
                style={{ background: `url(${medicin1})` }}
              >
                <div className="content">
                  <div className="title">ScentAware-8</div>
                  <p>Scentaware 8-scent Smell Test and Training kit</p>
                </div>
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="slide">
            <Link to="/productForResearchers#Scentaware16">
              <div
                className="ScentAwareTestKits_slider_card"
                style={{ background: `url(${medicin2})` }}
              >
                <div className="content">
                  <div className="title">ScentAware-16</div>
                  <p>Scentaware 16-scent Smell Test and Training kit</p>
                </div>
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="slide">
            <Link to="/productForResearchers#Scentaware16">
              <div
                className="ScentAwareTestKits_slider_card"
                style={{ background: `url(${medicin3})` }}
              >
                <div className="content">
                  <div className="title">ScentAware Pro</div>
                  <p>Scentaware Pro 16 Smell Test and Training kit</p>
                </div>
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="slide">
            <Link to="/productForResearchers#Scentaware16">
              <div
                className="ScentAwareTestKits_slider_card"
                style={{ background: `url(${medicin4})` }}
              >
                <div className="content">
                  <div className="title">ScentAware DIY</div>
                  <p>Create your own olfactory test.</p>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="paginationContainer">
        <div className="custom-pagination"></div>
      </div>
    </div>
  );
}

export default ScentAwareTestKits;
