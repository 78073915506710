import './ProductForResearchersBanner.css'

function ProductForResearchersBanner() {
  return (
    <div className='ProductForResearchersBanner_container'>
        <div className='ProductForResearchersBanner'>
            <h1>Advanced Biomarker Tools for Neurodegeneration Studies</h1>
            <p>Explore our products, choose the right tools for your study, and accelerate early detection.</p>
        </div>
    </div>
  )
}

export default ProductForResearchersBanner