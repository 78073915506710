import "./Pipeline.css";
import bg1 from '../../assets/images/bg1.png'
import bg2 from '../../assets/images/bg2.png'
import bg3 from '../../assets/images/bg3.png'
import bg4 from '../../assets/images/bg4.png'
import medicin1 from '../../assets/images/medicin2.jpg'
import concept from '../../assets/images/concept.svg'
import researchPartner1 from '../../assets/images/researchPartner1.png';
import researchPartner2 from '../../assets/images/researchPartner2.png';
import researchPartner3 from '../../assets/images/researchPartner3.png';
import researchPartner4 from '../../assets/images/researchPartner4.png';
import researchPartner6 from '../../assets/images/researchPartner6.png';
import researchPartner7 from '../../assets/images/researchPartner7.png';
import researchPartner8 from '../../assets/images/researchPartner8.png';

function Pipeline() {
  return (
    <div className="PipelineContainer">
      <div className="Pipeline">
        <div className="heading">Pipeline</div>
        <div className="w-100">
          <div className="row g-1 m-0">
            <div className="col-4 mx-auto">
              <span className="pipelineHeader">Development Stage</span>
            </div>
          </div>
          <div className="row g-1 m-0 mb-3">
            <div className="col-4">
              <span className="pipelineHeader">Product</span>
            </div>
            <div className="col-2">
              <span className="pipelineHeader">Concept</span>
            </div>
            <div className="col-2">
              <span className="pipelineHeader">Validation</span>
            </div>
            <div className="col-4">
              <span className="pipelineHeader">Research partners</span>
            </div>
          </div>
          <div className="row m-0 tableRow">
            <div className="col-4 pipeLine_product h-100">
                <div className="row m-0 h-100">
                    <div className="col-6 productImg">
                        <img src={bg1} alt="bg1" />
                    </div>
                    <div className="col-6 product_detail">
                        <div className="productName">IntelliVista</div>
                        <div className="description">Cloud-based analytics system integrating all SensifyLife data collection modules.</div>
                    </div>
                </div>
            </div>
            <div className="col-4 product_concept_validation">
                <img src={concept} alt="concept" />
                <div className="progressBar">
                    <div className="progress" style={{width: '100%'}}></div>
                </div>
            </div>
            <div className="col-4 product_research_partners">
                <img src={researchPartner1} alt="researchPartner1" />
                <img src={researchPartner8} alt="researchPartner2" />
            </div>
          </div>
          <div className="row m-0 tableRow">
            <div className="col-4 pipeLine_product h-100">
                <div className="row m-0 h-100">
                    <div className="col-6 productImg">
                        <img src={medicin1} alt="bg1" />
                    </div>
                    <div className="col-6 product_detail">
                        <div className="productName">ScentAware</div>
                        <div className="description">Only digital and at-home olfactory testing system.</div>
                    </div>
                </div>
            </div>
            <div className="col-4 product_concept_validation">
                <img src={concept} alt="concept" />
                <div className="progressBar">
                    <div className="progress" style={{width: '100%'}}></div>
                </div>
            </div>
            <div className="col-4 product_research_partners">
                <img src={researchPartner1} alt="researchPartner1" />
                <img src={researchPartner7} alt="researchPartner2" />
                <img src={researchPartner6} alt="researchPartner3" />
                <img src={researchPartner4} alt="researchPartner4" />
            </div>

          </div>
          <div className="row m-0 tableRow">
            <div className="col-4 pipeLine_product h-100">
                <div className="row m-0 h-100">
                    <div className="col-6 productImg">
                        <img src={bg2} alt="bg1" />
                    </div>
                    <div className="col-6 product_detail">
                        <div className="productName">Cognition</div>
                        <div className="description">Cognitive markers for associative, visuospatial and auditory memory.
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-4 product_concept_validation">
                <img src={concept} alt="concept" />
                <div className="progressBar">
                    <div className="progress" style={{width: '60%'}}></div>
                </div>
            </div>
            <div className="col-4 product_research_partners">
            </div>
            
          </div>
          <div className="row m-0 tableRow">
            <div className="col-4 pipeLine_product h-100">
                <div className="row m-0 h-100">
                    <div className="col-6 productImg">
                        <img src={bg4} alt="bg1" />
                    </div>
                    <div className="col-6 product_detail">
                        <div className="productName">RenAware</div>
                        <div className="description">At-home test for 12 metabolic markers for kidney, liver and diabetes using urine. 

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-4 product_concept_validation">
                <img src={concept} alt="concept" />
                <div className="progressBar">
                    <div className="progress" style={{width: '60%'}}></div>
                </div>
            </div>
            <div className="col-4 product_research_partners">
            </div>
            
          </div>
          <div className="row m-0 tableRow">
            <div className="col-4 pipeLine_product h-100">
                <div className="row m-0 h-100">
                    <div className="col-6 productImg">
                        <img src={bg3} alt="bg1" />
                    </div>
                    <div className="col-6 product_detail">
                        <div className="productName">OCT-Direct</div>
                        <div className="description">Retinal Imaging System using Optical Coherence Tomography.
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-4 product_concept_validation">
                <img src={concept} alt="concept" />
                <div className="progressBar">
                    <div className="progress" style={{width: '25%'}}></div>
                </div>
            </div>
            <div className="col-4 product_research_partners">
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pipeline;
