import axios from "axios";
import "./ScentAwareProducts.css";
import { useEffect, useState } from "react";
import ProductComponent from "../ProductComponent/ProductComponent";
import config from "../../config";
import { useSelector } from "react-redux";

function ScentAwareProducts() {
  const products = useSelector((state) => state.products.individualProducts)
  let link = "https://sensify-usermanual.s3.us-east-1.amazonaws.com/User+Manual+ScentAware+Olfactory+Evaluation+Kit.pdf";
  return (
    <>
      <div className="ScentAwareProductsContainer">
        <div className="ScentAwareProducts">
          <div className="topDetail">
            <div className="heading">ScentAware</div>
            <p>
              A platform that informs about imminent olfactory health concerns
              and motivates to take measures proactively.
            </p>
          </div>
          <div className="row m-0">
            {products.map((product, index) => {
              return <ProductComponent product={product} key={index} manual={link} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ScentAwareProducts;
