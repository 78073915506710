import React, { useState } from "react";
import "./ProductComponent.css";
import leftArrow from "../../assets/images/left-move.svg";
import rightArrow from "../../assets/images/right-move.svg";
import addToCart from "../../assets/images/addToCart.svg";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addInToCart } from "../../redux/slice/cartSlice";

function ProductComponent({ product, isLeft, manual }) {
  const [productQty, setproductQty] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const decrement = () => {
    if (productQty > 1) {
      setproductQty(productQty - 1);
    }
  };
  const increment = () => {
    setproductQty(productQty + 1);
  };

  const addCart = async (Id) => {
    dispatch(addInToCart(Id, productQty, navigate));
  };

  return (
    <>
      <div className="col-12 col-md-6  col-lg-12 ProductMainContainer">
        <div className={`ProductContainer ${isLeft ? "left" : "right"}`}>
          <div className="content">
            <div className="title">{product.ProductName}</div>

            <div className="d-flex align-items-center gap-20">
              <div className="price">${product.Amount}</div>
              <div className="count">
                <img
                  src={leftArrow}
                  alt="leftArrow"
                  onClick={() => decrement()}
                />{" "}
                <small>{productQty}</small>
                <img
                  src={rightArrow}
                  alt="rightArrow"
                  onClick={() => increment()}
                />
              </div>
              <span className="text-dark-small-thin">unit</span>
            </div>
            <div className="d-flex flex-column gap-10">
              <div className="text-dark-small-bold">
                Shelf life :{product.ShelfLife}
              </div>
              <div className="text-dark-small-thin">
                {product.PrescriptionHeading}
              </div>
              <div className="text-dark-small-thin">{product.Description}</div>
            </div>
            <div className="btn-container">
              {/* <button className="btn-rounded-blue">Buy now</button> */}
              <button
                className="btn-rounded-blue-outline"
                onClick={() => addCart(product.Id)}
              >
                <img src={addToCart} alt="addToCart" />
                Add to cart
              </button>
              <a 
              href={manual} 
              target="_blank"
              >
                <button
                  className="btn-rounded-blue-outline download-btn"
                >
                  <span>Download manual</span>
                </button>
              </a>
            </div>
          </div>
          <div className="img">
            <img
              src={
                `https://sensify-product-images.s3.us-east-1.amazonaws.com/` +
                product.Image
              }
              alt="product"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductComponent;
