import AlzheimersDisease from "../../components/AlzheimersDisease/AlzheimersDisease";
import Comprehensive from "../../components/Comprehensive/Comprehensive";
import ConcordanceOfScentAware from "../../components/ConcordanceOfScentAware/ConcordanceOfScentAware";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import Harness from "../../components/Harness/Harness";
import Header from "../../components/Header/Header";
import IntelliVista from "../../components/IntelliVista/IntelliVista";
import MetabolicDiseases from "../../components/MetabolicDiseases/MetabolicDiseases";
import OurApproach from "../../components/OurApproach/OurApproach";
import ParkinsonsDisease from "../../components/ParkinsonsDisease/ParkinsonsDisease";
import Pipeline from "../../components/Pipeline/Pipeline";
import PreBuilt from "../../components/PreBuilt/PreBuilt";
import RenAware from "../../components/RenAware/RenAware";
import ResearchersBanner from "../../components/ResearchersBanner/ResearchersBanner";
import ScentAwareSmellLoss from "../../components/ScentAwareSmellLoss/ScentAwareSmellLoss";
import "./Researchers.css";

function Researchers() {
  return (
    <>
      <Header />
      <ResearchersBanner />
      <OurApproach />
      <Pipeline />
      <IntelliVista />
      <Harness />
      <ScentAwareSmellLoss />
      <ParkinsonsDisease />
      <AlzheimersDisease />
      <ConcordanceOfScentAware />
      <MetabolicDiseases />
      <RenAware />
      <Comprehensive />
      <PreBuilt />
      <Contact />
      <Footer/>
    </>
  );
}

export default Researchers;
