import './IndividualsUsed.css'
import RightArrow from '../../assets/images/right-btn-arrow.svg'
import { Link } from 'react-router-dom'

function IndividualsUsed() {
    return (
        <div className="IndividualsUsedContainer">
            <div className="IndividualsUsed">

                <div className='IndividualsUsedContent'>
                    <div className="title">
                        5,000+ individuals have used Scentaware.
                    </div>
                    <p>The Scentaware Smell Test Kits are available as 16-Scent Kit for detailed assessment and the 8-Scent Kit for quick checks. Easy to use and effective, these kits are essential tools for anyone concerned about maintaining and understanding their olfactory health.</p>
                    <Link to="/#productVideo"><button> <img src={RightArrow} alt="RightArrow" />Watch the Video : ScentAware</button></Link>
                </div>
            </div>
            <div className='IndividualsUsedContentBottom'>
                <div className="title">
                    5,000+ individuals have used Scentaware.
                </div>
                <p>The Scentaware Smell Test Kits are available as 16-Scent Kit for detailed assessment and the 8-Scent Kit for quick checks. Easy to use and effective, these kits are essential tools for anyone concerned about maintaining and understanding their olfactory health.</p>
                <Link to="/#productVideo"><button> <img src={RightArrow} alt="RightArrow" />Watch the Video : ScentAware</button></Link>
            </div>
        </div>
    )
}

export default IndividualsUsed
