import React from 'react'
import './ForResearchers.css'
import arrowOutward from '../../assets/images/arrow-outward.svg'
import { Link } from 'react-router-dom'

function ForResearchers() {
  return (
    <div className='ForResearchers row m-0 g-3'>
        <div className='col-12 col-md-6 ForResearchers_left'>
            <h2>For Researchers and Drug Development</h2>
            <p>From research to clinical trials, our tools provide reliable, scalable testing solutions. Gather scalable real-world Olfactory, Cognitive and metabolic data for your clinical study.</p>
            <Link to={'/researchers'}><button className='LearnMore'><img src={arrowOutward} alt="arrowOutward" />Learn more</button></Link>
        </div>
        <div className='col-12 col-md-6 ForResearchers_right'></div>
      </div>
  )
}

export default ForResearchers
