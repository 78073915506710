import { configureStore } from "@reduxjs/toolkit";
import productSlice from '../slice/productSlice'
import cartSlice from '../slice/cartSlice'
import loaderSlice from '../slice/loaderSlice'
export const store = configureStore({
    reducer : {
        products: productSlice,
        cart: cartSlice,
        loader: loaderSlice
    }
})