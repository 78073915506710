import './OurApproach.css';
import approachImage1 from '../../assets/images/search.png';
import approachImage2 from '../../assets/images/access.png';
import approachImage3 from '../../assets/images/deploy.png';

function OurApproach() {
  return (
    <div className='OurApproach'>
        <h3>Our Approach : <br />
        Digitize what’s needed, when needed.</h3>
        <div className='OurApproach_cards row m-0'>
            <div className='OurApproach_card col-12 col-lg-4'>
                <img src={approachImage1} alt={'approachImage1'} />
                <p>Identify impactful biomarkers.</p>
            </div>
            <div className='OurApproach_card col-12 col-lg-4'>
                <img src={approachImage2} alt={'approachImage1'} />
                <p>Enable greater Access and Scalability in digitizing the biomarkers.</p>
            </div>
            <div className='OurApproach_card col-12 col-lg-4'>
                <img src={approachImage3} alt={'approachImage1'} />
                <p>Develop and deploy.</p>
            </div>
        </div>
    </div>
  )
}

export default OurApproach