import { Link } from 'react-router-dom'
import './IntelliVista.css'
function IntelliVista() {
  return (
    <div className='IntelliVista'>
        <div className='text-content'>
            <h2>IntelliVista : <br />
            One Platform that Collects data for your research.</h2>
            <p>IntelliVista is a HIPAA compliant data management tool that securely manages your data. It gathers in-depth data from the tests performed via the SensifyAware App and provides detailed visual infographics of how the participants perform.</p>
            <Link to="/productForResearchers"><button>View products for Research</button></Link>
        </div>
    </div>
  )
}

export default IntelliVista