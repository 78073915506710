import "./AlzheimersDisease.css";
import AlzheimersImg from "../../assets/images/Alzheimers.png";
function AlzheimersDisease() {
  return (
    <div className="AlzheimersDiseaseContainer">
        <div className="AlzheimersDisease">
        <div className="row m-0 h-100 flex-wrap AlzheimersDiseaseRow">
            <div className="col-12 col-lg-8 AlzheimersDisease_left">
            <img src={AlzheimersImg} alt="" />
            </div>
            <div className="col-12 col-lg-4 AlzheimersDisease_right">
                <div className="heading">Alzheimer’s Disease</div>
                <p>The centers of the brain where amyloid plaques and tau tangles starts to form first are also the centers needed for olfactory processing. </p>
                <div>
                    <ul>
                        <li><p>85 - 90 % of people with Alzheimer’s Disease have olfactory deficits</p></li>
                        <li><p>75 -80 % of individuals with amnestic mild cognitive impairment (aMCI) have olfactory deficits</p></li>
                        <li><p>60 - 80% of individuals with MCI have olfactory deficits</p></li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
    </div>
  );
}

export default AlzheimersDisease;
