import React from 'react'
import './Scentaware.css'
import { Link } from 'react-router-dom'

function Scentaware() {
  return (
    <div className='ScentawareContainer'>
       <div className='Scentaware'>
            <div className='detail'>
                <h2>Scentaware, <br />
                A comprehensive solution for smell evaluation.</h2>
                <p>Our ScentAware kits offer upto 16 unique aromas for comprehensive smell testing, providing an accessible at-home solution with a free, easy-to-use mobile app available on both Android and iOS.</p>
                <div className='btnSection'>
                    <Link to='/productForResearchers#Scentaware16'><button className='Scentaware-16'>Buy Scentaware-16 kit</button></Link>
                    <Link to='/productForResearchers#Scentaware16'><button className='Scentaware-8'>Buy Scentaware-8 kit</button></Link>
                </div>
            </div>
      </div>
    </div>
  )
}

export default Scentaware
