import './Harness.css'
import smell from '../../assets/images/smell-white.svg'
import metabolic from '../../assets/images/metabolic-white.svg'
import cognitive from '../../assets/images/cognitive-white.svg'
function Harness() {
  return (
    <div className='Harness'>
        <h4>Harness the power of multimodal digital analysis</h4>
        <div className='row m-0 w-100 HarnessCards'>
            <div className='col-12 col-md-4 HarnessCard'>
                <div>
                    <div className='icon'><img src={smell} alt="smell" /></div>
                </div>
                <div className='gap-8'>
                    <p>Olfactory Boiomarker</p>
                    <p>ScentAware Smell Evaluation kits for individuals and researchers.</p>
                </div>
            </div>
            <div className='col-12 col-md-4 HarnessCard'>
                <div>
                    <div className='icon'><img src={cognitive} alt="cognitive" /></div>
                </div>
                <div className='gap-8'>
                    <p>Cognitive Biomarkers</p>
                    <p>Through the SensifyAware mobile application.</p>
                </div>
            </div>
            <div className='col-12 col-md-4 HarnessCard'>
                <div>
                    <div className='icon'><img src={metabolic} alt="metabolic" /></div>
                </div>
                <div className='gap-8'>
                    <p>Metabolic Biomarkers</p>
                    <p>With RenAware urine test strips for self testing of 12 metabolic markers.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Harness