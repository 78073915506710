import './MetabolicDiseases.css'

function MetabolicDiseases() {
  return (
    <div className="MetabolicDiseasesContainer">
      <div className="MetabolicDiseases row m-0">
        <div className='col-12 col-md-5'>
            <div className="heading">Metabolic Diseases 
            like Chronic Kidney Disease (CKD) and Diabetes can lead to misdiagnosis of Alzheimer’s Disease </div>
        </div>
        <div className='col-12 col-md-6'>
            <p>At-home urine analysis offers a convenient way to determine metabolic health. </p>
        </div>
      </div>
    </div>
  );
}

export default MetabolicDiseases;
