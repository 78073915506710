import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useGlobalContext } from "../context/context";
import { useLocation } from "react-router-dom";
import App from "./App/App";
import { isMobile } from "react-device-detect";
import { useNavigate, Link } from 'react-router-dom';
import logoBlack from "../assets/logo-black.png";
import right_arrow from "../assets/right_arrow.png";
import NewFooter from "./Reuseable Components/NewFooter";
import * as Yup from "yup";
import { StaticHeader } from "./StaticHeader/StaticHeader";
import Footer from "./Footer/Footer";

const Payment = (props) => {
  const [PublishableKey, setPublishableKey] = useState();
  const [load, setLoad] = useState(false);
  let res = {};
  var thenum = 0;
  const location = useLocation();
  const { cart, total, increase2, amount3 } = useGlobalContext();
  const navigate = useNavigate();
  const [userName, setUserName] = useState([]);

  useEffect(() => {
    const userDataByAccounts = JSON.parse(localStorage.getItem('socialObj'));
    const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
    if (userDataByAccounts) {
      const Name = userDataByAccounts.FirstName + ' ' + userDataByAccounts.LastName;
      setUserName(Name);
    } else if (userDataByEmail) {
      setUserName(userDataByEmail.Data.UserName);
    } else {
      setUserName(null);
    }
  }, []);

  const handleClick = () => {
    navigate("/Shipping");
  };

  return (
    <>
      <body className="renaware">
        <StaticHeader />
        <div>
          <App
          // data={data}
          />
        </div>
        <Footer />
      </body>
    </>
  );
};



export default Payment;

