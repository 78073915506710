import "./ContactModal.css";
import closeIcon from "../../assets/images/close.svg";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import axios from "axios";
import { message } from "antd";
import { useState } from "react";
const initialValues = {
  name: "",
  organization: "",
  email: "",
  message: "",
};
function ContactModal({ show, setShow }) {

  const [load, setLoad] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required!"),
    organization: Yup.string().required("Organization is required!"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
    message: Yup.string().required("Message is required!"),
  });
  const handleSubmit = async (values) => {
    setLoad(true);
    try {
      const data = {
        Name: values.name,
        Email: values.email,
        Organisation: values.organization,
        Message: values.message,
      };
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        "https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/admin/contact-form",
        data,
        { headers }
      );
      message.open({
        type: "success",
        content: "Thank you for submitting Query",
        style: {
          marginTop: "150px",
        },
      });
      formik.resetForm();
      setShow(false)
      setLoad(false);
    } catch (error) {
      console.log("error", error);
      setLoad(false);
      setShow(false)
    } finally {
      setLoad(false);
      setShow(false)
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
      dialogClassName="custom-dialog"
    >
      <Modal.Body>
        <img
          src={closeIcon}
          alt="closeIcon"
          className="modelcloseIcon"
          onClick={() => setShow(false)}
        />
        <button
          type="button"
          class="btn-close modelcloseIconWhite"
          onClick={() => setShow(false)}
          aria-label="Close"
        ></button>
        <form className="contactPopup" onSubmit={formik.handleSubmit}>
          <div className="row m-0 h-100 w-100">
            <div className="col-12 col-lg-7 contactPopupLeft">
              <div className="heading">Contact us now</div>
              <p>
                Take the first step towards advanced personal health monitoring.
                Please fill out the form to pre-order your kit.
              </p>
            </div>
            <form className="col-12 col-lg-5 contactPopupRight">
              <div className="form_group">
                <label htmlFor="fullName">Full name :</label>
                <input
                  type="text"
                  id="fullName"
                  placeholder="Enter your full name"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="name"
                  className={`form-control ${
                    formik.touched.name && formik.errors.name
                      ? "is-invalid"
                      : ""
                  }`}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  isValid={formik.isValid}
                  isTouched={formik.touched.name}
                  invalidFeedback={formik.errors.name}
                />
                <div className="invalid-feedback">{formik.errors.name}</div>
              </div>
              <div className="form_group">
                <label htmlFor="email">Email address :</label>
                <input
                  type="text"
                  id="email"
                  placeholder="Enter your email address"
                  className={`form-control ${
                    formik.touched.email && formik.errors.email
                      ? "is-invalid"
                      : ""
                  }`}
                  name="email"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  isValid={formik.isValid}
                  isTouched={formik.touched.email}
                  invalidFeedback={formik.errors.email}
                />
                <div className="invalid-feedback">{formik.errors.email}</div>
              </div>
              <div className="form_group">
                <label htmlFor="Organization">Organization :</label>
                <input
                  type="text"
                  id="Enter your organization"
                  placeholder="Organization"
                  className={`form-control ${
                    formik.touched.organization && formik.errors.organization
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.organization}
                  isValid={formik.isValid}
                  isTouched={formik.touched.organization}
                  invalidFeedback={formik.errors.organization}
                />
                <div className="invalid-feedback">
                  {formik.errors.organization}
                </div>
              </div>
              <div className="form_group">
                <label htmlFor="Description">Description :</label>
                <textarea
                  id="Description"
                  name="message"
                  placeholder="Description"
                  className={`form-control ${
                    formik.touched.message && formik.errors.message
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  isValid={formik.isValid}
                  isTouched={formik.touched.message}
                  invalidFeedback={formik.errors.message}
                ></textarea>
                <div className="invalid-feedback">{formik.errors.message}</div>
              </div>
              {/* <div className="form_group">
                  <label htmlFor="Quantity">Quantity :</label>
                  <input
                    type="text"
                    id="Quantity"
                    placeholder="How many units would you like to order?"
                  />
                </div> */}

              <div>
                <button
                  className="d-flex justify-content-center"
                  onClick={formik.handleSubmit}
                >
                  {load ? (
                    <div className="align-items-center d-flex justify-content-center">
                      <h1
                        class="loader"
                        disabled={load}
                        style={{
                          // color: "#101A36",
                          margin: "-5px",
                          width: "30px",
                          height: "30px",
                        }}
                      ></h1>
                    </div>
                  ) : (
                    ""
                  )}
                  <span>Submit</span>
                </button>
              </div>
            </form>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ContactModal;
