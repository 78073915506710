import React from 'react'
import './SilentWarning.css';
import eye from '../../assets/images/eye.svg';
import smell from '../../assets/images/smell.svg';
import speech from '../../assets/images/speech.svg';
import Hearing from '../../assets/images/Hearing.svg';
import gait from '../../assets/images/gait.svg';

function SilentWarning() {
    let leftCards = [
        {
            img: eye,
            heading: "Vision",
            description: "Changes in contrast sensitivity, peripheral vision."
        },
        {
            img: smell,
            heading: "Smell",
            description: "Smell deficits"
        },
        {
            img: speech,
            heading: "Speech",
            description: "Struggling to find the right words (word retrieval issues)."
        }
    ]
    let rightCards = [
        {
            img: Hearing,
            heading: "Hearing",
            description: "Slower auditory processing, difficulty distinguishing speech."
        },
        {
            img: gait,
            heading: "Gait",
            description: "Balance, rhythm and symmetry become progressively impaired."
        }
    ]
    return (
        <div className='SilentWarning'>
            <div className='SilentWarningDescription'>
                <span>The Silent Warning Signs: </span>
                <p>How Neurodegenerative Diseases Affect Your
                    Senses First...</p>
            </div>
            <div className='SilentWarningIconic row m-0'>
                <div className='col-12 col-lg-6 SilentWarningIconic_Section'>
                    {
                        leftCards.map((card, index) => {
                            return <div className='iconicCard' key={index}>
                                <div>
                                    <div className='icon'>
                                        <img src={card.img} alt={card.heading} />
                                    </div>
                                </div>
                                <div className='detail'>
                                    <h6>{card.heading}</h6>
                                    <p>{card.description}</p>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className='col-12 col-lg-6 SilentWarningIconic_Section_Right'>
                    {
                        rightCards.map((card, index) => {
                            return <div className='iconicCard' key={index}>
                                <div>
                                    <div className='icon'>
                                        <img src={card.img} alt={card.heading} />
                                    </div>
                                </div>
                                <div className='detail'>
                                    <h6>{card.heading}</h6>
                                    <p>{card.description}</p>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default SilentWarning
