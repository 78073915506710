import './ConcordanceOfScentAware.css'
import concordantImg from "../../assets/images/concordant.png";
function ConcordanceOfScentAware() {
  return (
    <div className="ConcordanceOfScentAwareContainer">
        <div className="ConcordanceOfScentAware">
            <div className="row m-0 h-100 flex-wrap ConcordanceOfScentAwareRow">
                <div className="col-12 col-lg-6 col-lg-5 ConcordanceOfScentAware_left">
                    <div className="heading">Concordance of ScentAware Test with B-SIT (12-item Brief Smell Identification Test)</div>
                    <h6>Why remote monitoring of olfaction is better? </h6>
                    <div>
                        <ul>
                            <li><p>Digital remote assessment reduce cost and time vs traditional tests.</p></li>
                            <li><p>Ability to easily collect longitudinal data.</p></li>
                            <li><p>Validated digital tests enable seamless Electronic Medical Record (EMR) integration.</p></li>
                            <li><p>Digital self-assessment eliminates the need for trained test administrators.</p></li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-5 ConcordanceOfScentAware_right">
                    <img src={concordantImg} alt="concordantImg" />
                    <p>Results of ScentAware and the B-SIT test, a standardized scratch and sniff test used in clinical research are correlated and consistent.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ConcordanceOfScentAware