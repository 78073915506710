import React from 'react'
import './OngoingStudies.css'

function OngoingStudies() {
  return (
    <div className='OngoingStudiesContainer'>
       <div className='OngoingStudies'>
            <div className='detail'>
                <span>Ongoing Studies & Partnerships</span>
                <p>SensifyLife is collaborating with leading research institutions to validate smell & digital tools.</p>
                <p>We partner with neurologists, cognitive scientists, and medical centers to push early detection forward.</p>
            </div>
            <div className='rightMap d-lg-block d-none'></div>
      </div>
    </div>
  )
}

export default OngoingStudies
