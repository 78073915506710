import React from "react";
import "./MultiModal.css";
import eye from "../../assets/images/eye-dark.svg";
import smell from "../../assets/images/smell-dark.svg";
import metabolic from "../../assets/images/metabolic.svg";
import cognitive from "../../assets/images/cognitive.svg";

function MultiModal() {
  let cards = [
    {
      img: smell,
      heading: "Olfactory Biomarkers",
      description:
        "The loss of smell is one of the earliest and most consistent signs of neurodegeneration.",
    },
    {
      img: eye,
      heading: "Retinal Biomarkers",
      description:
        "The eye provides a window into brain health, revealing early markers of decline.",
    },
    {
      img: metabolic,
      heading: "Metabolic Biomarkers",
      description:
        "Metabolic markers can be used to rule out conditions that resemble neurodegeneration",
    },
    {
      img: cognitive,
      heading: "Cognitive Biomarkers",
      description:
        "Cognitive markers can identify cognitive domains affected in the process",
    },
  ];
  return (
    <div className="MultiModal">
      <div className="row m-0 w-100 h-100 align-items-center">
        <div className="col-6 d-lg-flex d-none MultiModalImgContainer">
          <div className="MultiModalImg"></div>
        </div>
        <div className="col-12 col-lg-6 MultiModalContent">
          <div className="d-flex flex-column gap-24">
            <span>
              A multimodal approach : <br />
              The first senses affected in neurodegenerative diseases.
            </span>
            <p>
              Multi-modal analysis of diverse biomarker signals enhances the
              ability to detect early-stage neurodegenerative diseases.
            </p>
          </div>
          <div className="MultiModalContentCardsContainer">
            {cards.map((card, index) => {
              return (
                <div className="MultiModalContentCard" key={index}>
                  <div>
                    <div className="icon">
                      <img src={card.img} alt={card.heading} />
                    </div>
                  </div>
                  <div className="detail">
                    <h6>{card.heading}</h6>
                    <p>{card.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiModal;
