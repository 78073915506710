import React, { useState, useEffect } from "react";
import Mockup_16tube from "../assets/Mockup_16tube.svg";
import shop_arrow from "../assets/shop_arrow.svg";
import styled from "styled-components";
import { useGlobalContext } from "../context/context";
import { isMobile } from 'react-device-detect';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import logoBlack from "../assets/logo-black.png";
import product_checkout from "../assets/product_checkout.png";
import product_checkout_2 from "../assets/product_checkout_2.png";
import NewFooter from "./Reuseable Components/NewFooter";
import data from "../data";
import Axios from "axios";
import config from "../config.js";
import moment from 'moment';
import { StaticHeader } from "../components/StaticHeader/StaticHeader.jsx";
import Footer from "../components/Footer/Footer.jsx";
const Olfaction = () => {
  const location = useLocation();
  const { name, total } = useGlobalContext();
  const navigate = useNavigate();
  const today = new Date();
  today.setDate(today.getDate() + 6);
  const [userName, setUserName] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [totalAmmount, setTotalAmmount] = useState(0);
  const [orderData, setOrderData] = useState({
    orderNumber: '',
    shippingFormData: ''
  });
  const [lastUserOrderData, setLastUserOrderData] = useState([]);
  useEffect(() => {
     console.log("data.js",data)
    let userId = 0
    const userDataByAccounts = localStorage.getItem('social_login_ID');
    const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
    if (userDataByAccounts) {
      userId = userDataByAccounts
    } 
    if (userDataByEmail) {
      userId = userDataByEmail.Data.UserId
    } 

    getLastUserOrderData(userId)
  }, []);
  const getLastUserOrderData = async (userId) => {
    try {
      const obj = {
        Url: "/get-last-order",
        UserId: parseInt(userId),
      };
      let api = `${config.BASE_URL}`
      const response = await Axios.post(
        api, obj
      );
      setLastUserOrderData(response.data?.OrderDetailModelList[0])
    } catch (error) {
      // console.error('Error updating profile:', error);
    }
  };
  useEffect(() => {
    const TotalAmount = localStorage.getItem("TotalAmount1");
    const finalAmmount = localStorage.getItem("total");
    setTotalAmmount(finalAmmount);
    const tempCart = localStorage.getItem("tempCart");
    const PersistedCart = JSON.parse(tempCart);
    if (TotalAmount > 0) {
      const itemsToStore = PersistedCart?.filter(
        (item) =>
          (item.id === 2 || item.id === 1 || item.id === 3 || item.id === 4 || item.id === 5) && item.amount > 0
      );
      setCartData(itemsToStore);
    }
  }, []);

  useEffect(() => {
    const userDataByAccounts = JSON.parse(localStorage.getItem('socialObj'));
    const userDataByEmail = JSON.parse(localStorage.getItem('UserData'));
    if (userDataByAccounts) {
      const Name = userDataByAccounts.FirstName + ' ' + userDataByAccounts.LastName;
      setUserName(Name);
    } else if (userDataByEmail) {
      setUserName(userDataByEmail.Data.UserName);
    } else {
      setUserName(null);
    }
    const orderNumber = JSON.parse(localStorage.getItem('orderNumber'));
    const shippingFormData = JSON.parse(localStorage.getItem('shippingFormData'));
    setOrderData({
      orderNumber: orderNumber ? orderNumber : '',
      shippingFormData: shippingFormData ? shippingFormData : {},
    });
  }, []);

  const getDeliveryDate = () => {
    let date = today.getDate() + "/" + parseInt(today.getMonth() + 1) + "/" + today.getFullYear();
    return date;
  }
  const handleClickbtn = () => {
    // 👇️ navigate programmatically
    localStorage.removeItem('shippingFormData');
    localStorage.removeItem('orderNumber');
    localStorage.removeItem('tempCart');
    localStorage.removeItem('total');
    localStorage.removeItem('subtotal');
    var obj = localStorage.getItem("userid-local_storage");
    navigate('/');
  }
  return (
    <>
      <body className="renaware">
      <StaticHeader />

        <section className="login_page inner-banner-section sign_up_outer d-flex align-items-center">
          <div class="container pb-lg-3 pb-md-3">
            <div class="row">
              <div
                class="col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="card_title">
                  <h2 style={{ fontWeight: 300, color: '#4E4E4E' }}
                    class="mb-4 pb-lg-2">Your order has been placed
                    successfully!</h2>
                  <p class="text-start mb-0">We have sent an email
                    confirmation to your registered email ID. Rest
                    assured, your payment information is handled
                    with the highest level of security and
                    confidentiality. We employ advanced encryption
                    and security protocols to ensure your data is
                    protected and stored securely.</p>
                </div>
              </div>
            </div>
            <div class="row mt-4 pt-lg-2">
              <div class="col-lg-8 col-md-12 col-sm-12 col-12 pb-lg-2">
                <div class="row">
                  {cartData.map((item, index) => (
                    <><div class="col-lg-4 col-md-4 col-sm-12 col-12">
                      <div class="product_img">
                        <img src={item.img} width="100%" alt="" />
                      </div>
                    </div><div
                      class="col-lg-8 col-md-9 col-sm-12 col-12 d-flex align-items-start mt-lg-0 mt-md-4 mt-3">
                        <div class="card_title cart_sec_product">
                          <h6 class="mb-0 blue">{item.main_header}</h6>
                          <p
                            class="d-flex align-items-center text-start gap-4 mt-3 mb-0"><b>Order
                              ID :</b> <b>{lastUserOrderData.OrderNumber}</b></p>
                          <p
                            class="d-flex align-items-start text-start gap-4 mt-3 mb-0"><b>Deliver
                              to :</b> <span>{lastUserOrderData.ShippingToName}<br />
                             {lastUserOrderData.AddressLine}, {lastUserOrderData.City},<br />
                              {lastUserOrderData.ZipCode}</span></p>
                          <p
                            class="d-flex align-items-center text-start gap-4 mt-3 mb-0"><b>Delivery
                              by</b> <span>  {moment().add(5, "days").format("DD-MMMM-YYYY")}</span></p>
                        </div>

                      </div>
                      {index !== cartData.length - 1 && <div class="col-12">
                        <hr class="my-4" />
                      </div>}
                      </>
                  ))}
                </div>
              </div>
              <div class="col-12 mt-4 pt-lg-2 pt-2 ">
                <button class="btn btn-primary w-auto confirm_button d-md-inline-block d-none" onClick={handleClickbtn}>Back to homepage</button>
              </div>
              <div class="row pt-lg-3 pt-md-3 pt-2">
                <div class="col-12 mt-4">
                  <button class="btn btn-primary d-lg-none d-md-none d-block w-100" onClick={handleClickbtn} >
                  Back to homepage</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </body>

    </>
  );
};


export default Olfaction;




