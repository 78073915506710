import { createSlice } from "@reduxjs/toolkit";
import config from "../../config";
import axios from "axios";

let api = `${config.BASE_URL}`;
const UserData = localStorage.getItem("UserData") || null;

export const getCartDetail = () => (dispatch) => {
  const currentUserId = JSON.parse(UserData)?.Data?.UserId || 0;
  const obj = {
    Url: "/get-cart-details-user",
    userId: currentUserId,
  };
  axios.post(api, obj).then((res) => {
    dispatch(setCartDetails(res.data.UserCartDetail));
  }).catch((err) => {
    console.log(err);
  });
};

export const addInToCart = (Id, productQty, navigate) => (dispatch) => {
  const UserData = localStorage.getItem("UserData") || null;
  const currentUserId = JSON.parse(UserData)?.Data?.UserId || 0;
  if(currentUserId){
    const obj = {
      Url: "/add-cart-details",
      userId: currentUserId,
      ProductId: Id,
      Quantity: productQty,
    };
    axios.post(api, obj).then((res) => {
      if (res.data.IsSuccess) {
        dispatch(getCartDetail());
        navigate("/cart");
      }
    });
  }else{
    navigate(window.location.pathname + window.location.hash, { state : "login"});
  }
};

export const removeCart = (Id) => async (dispatch) => {
  const UserData = localStorage.getItem("UserData") || null;
  const currentUserId = JSON.parse(UserData)?.Data?.UserId || 0;
  const obj = {
    Url: "/remove-cart-item",
    userId: currentUserId,
    ProductId: Id,
  };
  let api = `${config.BASE_URL}`;
  await axios.post(api, obj).then(() => {
    dispatch(getCartDetail());
  });
};

export const increaseCartItem = (Id) => async (dispatch) => {
  const UserData = localStorage.getItem("UserData") || null;
  const currentUserId = JSON.parse(UserData)?.Data?.UserId || 0;
  const obj = {
    Url: "/update-cart-quantity-increment-one",
    userId: currentUserId,
    ProductId: Id,
  };
  let api = `${config.BASE_URL}`;
  await axios.post(api, obj).then(() => {
    dispatch(getCartDetail());
  });
};
export const decreaseCartItem = (Id) => async (dispatch) => {
  const UserData = localStorage.getItem("UserData") || null;
  const currentUserId = JSON.parse(UserData)?.Data?.UserId || 0;
  const obj = {
    Url: "/update-cart-quantity-decrement-one",
    userId: currentUserId,
    ProductId: Id,
  };
  let api = `${config.BASE_URL}`;
  await axios.post(api, obj).then(() => {
    dispatch(getCartDetail());
  });
};

const cartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    setCartDetails: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setCartDetails } = cartSlice.actions;

export default cartSlice.reducer;
