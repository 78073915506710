import React, { useState } from "react";
import Footer from "../Footer/Footer";
import MainCheckOut from "../../pages/MainCheckOut";
import Header from "../Header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import right_arrow from "../../assets/right_arrow.png";
import Checkout from "../../components/Checkout.jsx";
import config from "../../config";
import './Cart.css';
import { StaticHeader } from "../StaticHeader/StaticHeader.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getCartDetail } from "../../redux/slice/cartSlice.js";

export const Cart = () => {
  const [userName, setUserName] = useState([]);
  const TotalAmount = localStorage.getItem("TotalAmount1");
  const tempCart = localStorage.getItem("tempCart");
  const PersistedCart = JSON.parse(tempCart);
  const navigate = useNavigate();
  let isok = false;
  let linkval = "/Shipping";
  let objval = { haider: "Shipping" };
  let cartLength = 0;
  const getData = (linkval, objval) => {
    navigate("/Shipping", { state: { objval } });
  };
  const location = useLocation();
  useEffect(() => {
    // debugger;
    // getCart();

    const userDataByAccounts = JSON.parse(localStorage.getItem("socialObj"));
    const userDataByEmail = JSON.parse(localStorage.getItem("UserData"));
    if (userDataByAccounts) {
      const Name =
        userDataByAccounts.FirstName + " " + userDataByAccounts.LastName;
      setUserName(Name);
    } else if (userDataByEmail) {
      setUserName(userDataByEmail.Data.UserName);
    } else {
      setUserName(null);
    }
  }, []);

  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.cart)

  // const getCart = async () => {
  //   dispatch(getCartDetail())
  // };
  console.log(cartDetails)
  // setCartData(response.data.UserCartDetail || []);

  const [cartItemCount, setCartItemCount] = useState(0);
  const test = cartDetails.length;
  if (test > 0) {
    isok = true;
  } else {
    isok = false;
  }
  useEffect(() => {
    // debugger;
    if (test == 0) {
      setCartItemCount(0);
    } else if (test > 0) {
      const itemCount = PersistedCart?.filter(
        (item) =>
          (item.id === 2 ||
            item.id === 1 ||
            item.id === 3 ||
            item.id === 4 ||
            item.id === 5) &&
          item.amount > 0
      ).length;
      if (itemCount !== cartItemCount) {
        setCartItemCount(itemCount);
      }
    }
  }, [test]);
  useEffect(() => {
    if (location.state?.refresh) {
      // getCart();
    }
  }, [location]);
  if (isok) {
    return (
      <>
        <StaticHeader />
        <div className="renaware cartContainer">
          <section
            className="login_page inner-banner-section sign_up_outer d-flex align-items-start"
            style={{ minHeight: "auto" }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                  <ul class="card_breadcrumb">
                    <li
                      class="active"
                      onClick={() => navigate("/cart")}
                    >
                      <a>Cart</a>
                    </li>

                    <li
                      // onClick={() => navigate('/Shipping')}
                      disabled
                    >
                      <a>Shipping</a>
                    </li>

                    <li
                      // onClick={() => navigate('/Payment')}
                      disabled
                    >
                      <a>Checkout</a>
                    </li>

                    <button
                      class="btn btn-outline-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none"
                      onClick={() => navigate("/productForResearchers")}
                    >
                      Cancel order
                    </button>
                    <button
                      className={`btn btn-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none ${
                        cartDetails.length > 0 ? "" : "disabled"
                      }`}
                      onClick={() =>
                        cartDetails.length > 0 && navigate("/Shipping")
                      }
                      disabled={parseInt(test).length == 0}
                    >
                      {" "}
                      Add shipping details
                    </button>
                  </ul>
                </div>
              </div>
              <div class="row my-4 py-lg-2">
                <div class="col-12">
                  <div class="card_title">
                    <h6 class="m-0">
                      <b class="d-flex align-items-center gap-4">
                        <img
                          src={right_arrow}
                          class="d-lg-block d-md-block d-none"
                          onClick={() => navigate(-1)}
                          alt=""
                        />
                        There are {cartDetails.length} items in your cart.
                      </b>
                    </h6>
                  </div>
                </div>
              </div>
              {cartDetails?.map((item) => {
                return (
                  <Checkout
                    key={item.Products.Id}
                    {...item.Products}
                    Quantity={item.Quantity}
                  />
                );
              })}

              <div class="row pt-lg-3 pt-md-3 pt-2">
                <div class="col-12 mt-4">
                  <button
                    class={`btn btn-primary d-lg-none d-md-none d-block w-100 ${
                      cartDetails.length > 0 ? "" : "disabled"
                    }`}
                    onClick={() => cartDetails.length > 0 && navigate("/Shipping")}
                    disabled={parseInt(test).length == 0}
                  >
                    Add shipping details
                  </button>
                  <button
                    class="btn btn-outline-primary mt-lg-0 mt-md-3 mt-3 d-lg-none d-md-none d-block w-100"
                    onClick={() => navigate("/productForResearchers")}
                  >
                    Cancel order
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  } else if (!isok) {
    return (
      <>
        <StaticHeader />
        <div className="renaware cartContainer">
          <section
            className="login_page inner-banner-section sign_up_outer d-flex align-items-start"
            style={{ minHeight: "auto" }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                  <ul class="card_breadcrumb">
                    <li
                      class="active"
                      onClick={() => navigate("/cart")}
                    >
                      <a>Cart</a>
                    </li>
                    <li>
                      <a>Shipping</a>
                    </li>
                    <li>
                      <a>Checkout</a>
                    </li>
                    <button
                      class="btn btn-outline-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none"
                      onClick={() => navigate(-1)}
                    >
                      Cancel order
                    </button>
                    <button
                      className={`btn btn-primary w-auto ms-4 d-lg-inline-block d-md-inline-block d-none ${
                        cartDetails.length > 0 ? "" : "disabled"
                      }`}
                      onClick={() =>
                        cartDetails.length > 0 && navigate("/Shipping")
                      }
                    >
                      Add shipping details
                    </button>
                  </ul>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-start"></div>
              </div>
              <div class="row my-4 py-lg-2">
                <div class="col-12">
                  <div class="card_title">
                    <h6 class="m-0">
                      <b class="d-flex align-items-center gap-4">
                        <Link to={-1}>
                          <img
                            src={right_arrow}
                            class="d-lg-block d-md-block d-none"
                            alt
                          />
                        </Link>
                        There are 0 items in your cart.
                      </b>
                    </h6>
                  </div>
                </div>
                <div class="row pt-lg-3 pt-md-3 pt-2">
                  <div class="col-12 mt-4">
                    <button
                      class={`btn btn-primary d-lg-none d-md-none d-block w-100 ${
                        cartDetails.length > 0 ? "" : "disabled"
                      }`}
                      onClick={() =>
                        cartDetails.length > 0 && navigate("/Shipping")
                      }
                    >
                      Add shipping details
                    </button>
                    <button
                      class="btn btn-outline-primary mt-lg-0 mt-md-3 mt-3 d-lg-none d-md-none d-block w-100"
                      onClick={() => navigate(-1)}
                    >
                      Cancel order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  }
};
