import './AnnulPlan.css'
import img from '../../assets/images/mobileTablet.png'
import ContactModal from '../ContactModal/ContactModal';
import { useState } from 'react';
function AnnulPlan() {
    const [show, setShow] = useState(false);
  return (
    <>
    <div className='AnnulPlanContainer'>
        <div className='AnnulPlan'>
            <div className='bgImg'><img src={img} alt="img" width={'100%'}/></div>
            <div className="text-content">
                <div className="heading">IntelliVista : <br />
                Cost effective annual plan.</div>
                <p>Experience the full power of IntelliVista with a single, all-inclusive subscription plan. Designed to simplify your workflow and elevate your decision-making, we bring together everything you need in one seamless package. No more manual data collection.</p>
            </div>
            <div className='planCards'>
                <div className='planCard'>
                    <div className="title">For Researcher</div>
                    <ul>
                        <li>1 administrator account login.</li>
                        <li>1 moderator account login.</li>
                        <li>ScentAware, GlanceAware, AudioAware and TraceAware</li>
                        <li>Add up to 10,000 participants in a clinical study.</li>
                        <li>Track longitudinal data digitally.</li>
                    </ul>
                </div>
                <div className='planCard'>
                    <div className="title">For Companies</div>
                    <ul>
                    <li>Integration of Intellivista and test modules to your platform</li>
                    <li>ScentAware, GlanceAware, AudioAware RenAware, TraceAware and more</li>
                    <li>Unlimited clinical study set up.</li>
                    <li>Unlimited number of study participants</li>
                    </ul>
                </div>
            </div>
            <button onClick={() => setShow(true)}>Get a quote</button>
        </div>
    </div>
    <ContactModal show={show} setShow={setShow} />
    </>
  )
}

export default AnnulPlan