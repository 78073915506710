import React from 'react'
import './Individuals.css'
import arrowOutward from '../../assets/images/arrow-outward-light.svg'
import { Link } from 'react-router-dom'

function Individuals() {
  return (
    <div className='Individuals row m-0 gx-5'>
        <div className='Individuals_left col-12 col-md-6'></div>
        <div className='Individuals_right col-12 col-md-6'>
            <h2>For Individuals experiencing smell loss</h2>
            <p>Whether you're concerned about changes in your sense of smell or monitoring long-term patterns, the ScentAware Kit empowers you to track and understand your olfactory health from the comfort of your home.</p>
            <Link to={'/individuals'}><button className='LearnMore'><img src={arrowOutward} alt="arrowOutward" />Learn more</button></Link>
        </div>
    </div>
  )
}

export default Individuals
