import { Link } from 'react-router-dom'
import './ScentAwareSmellLoss.css'

function ScentAwareSmellLoss() {
  return (
    <div className='ScentAwareSmellLoss'>
        <div className='text-content'>
            <div className='heading'>ScentAware : <br />
            Smell loss as an early biomarker of neurodegeneration</div>

            <div className='bottom'>
                <p>Over 13 million people in America experience anosmia, a partial or complete loss of smell.</p>
                <Link to="/productForResearchers"><button>View products for Research</button></Link>
            </div>
        </div>
    </div>
  )
}

export default ScentAwareSmellLoss