import './PreBuilt.css'
import img from '../../assets/images/bg2-blue.png'

function PreBuilt() {
  return (
    <div className='PreBuiltContainer'>
        <div className='PreBuilt'>
            <div className='row m-0 h-100 w-100'>
                <div className='col-12 col-lg-6 text-content'>
                    <div className='text-content-insite'>
                        <div className='heading'>Pre-built cognitive tests in the SensifyAware app.</div>
                        <div>
                            <ul>
                                <li><p>TraceAware tracks visual memory and motor skills.</p></li>
                                <li><p>AudioAware tracks verbal memory.</p></li>
                                <li><p>GlanceAware tracks associative memory.</p></li>
                            </ul>
                        </div>
                    </div>
                    <div className='text-content-insite'>
                        <div className='heading'>Customize cognitive biomarkers 
                        for your research.</div>
                        <p>At Sensify, we specialize in developing bespoke digital biomarker solutions that transform mobile devices into powerful research tools. We offer custom applications designed to meet your research and clinical trial needs.</p>
                    </div>
                </div>
                <div className='col-12 col-lg-6 imageSection'>
                    <img src={img} alt="image" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default PreBuilt