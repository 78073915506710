import "./ParkinsonsDisease.css";

function ParkinsonsDisease() {
  return (
    <div className="ParkinsonsDiseaseContainer">
      <div className="ParkinsonsDisease">
        {/* <div className="text-content">
          <div className="heading">Parkinson’s Disease</div>
          <p>
            About 90% of people with Parkinson's Disease experience reduced
            ability to smell about a decade before other recognizable symptoms
            appear.
          </p>
          <div>
            <p>
              Olfactory deficits can be used to differentiate Parkinson’s
              Disease from other parkinsonism syndromes:
            </p>
            <ul>
              <li>Progressive Supraneural Palsy (PSP)</li>
              <li>Multiple System Atrophy (MSA)</li>
              <li>Cortico-Basal degeneration (CBD)</li>
            </ul>
          </div>
        </div> */}
      </div>
      <div className="text-content">
          <div className="heading">Parkinson’s Disease</div>
          <p>
            About 90% of people with Parkinson's Disease experience reduced
            ability to smell about a decade before other recognizable symptoms
            appear.
          </p>
          <div>
            <p>
              Olfactory deficits can be used to differentiate Parkinson’s
              Disease from other parkinsonism syndromes:
            </p>
            <ul>
              <li>Progressive Supraneural Palsy (PSP)</li>
              <li>Multiple System Atrophy (MSA)</li>
              <li>Cortico-Basal degeneration (CBD)</li>
            </ul>
          </div>
        </div>
    </div>
  );
}

export default ParkinsonsDisease;
