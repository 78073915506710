import React from 'react'
import './Footer.css'
import logo from '../../assets/images/logo.svg'
import facebook from '../../assets/images/facebook.svg'
import instagram from '../../assets/images/instagram.svg'
import linkedIn from '../../assets/images/linkedIn.svg'
function Footer() {
    return (
        <div className='Footer'>
            <div className='logo'>
                <img src={logo} alt="" />
            </div>
            <div className='detail row g-5'>
                <div className='col-12 col-md-6 col-lg-3'>
                    <div className='section'>
                        <h5>Contact us</h5>
                        <p>251 East 32nd Street 7C <br /> New York 10016 <br /> NY, United States</p>
                        <a href='mailto:info@sensifylife.com'><p className='email text-white'>info@sensifylife.com</p></a>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3'>
                    <div className='section'>
                        <h5>Privacy policy</h5>
                        <p>By visiting our Website and/or using the Services in any manner, you acknowledge that you accept the practices and policies outlined in our Privacy Policy.</p>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3'>
                    <div className='section'>
                        <h5>Get in touch with us</h5>
                        <div className='social'>
                            <a href='https://www.facebook.com/p/Sensify-Inc-100089377607943/?locale=tr_TR' target='_blank'><img src={facebook} alt="facebook" /></a>
                            <a href='https://www.linkedin.com/company/sensify-inc' target='_blank'><img src={linkedIn} alt="linkedIn" /></a>
                            <a href='https://www.instagram.com/sensifyaware/?igsh=MTFqY29kbW9uaHF2Ng%3D%3D' target='_blank'><img src={instagram} alt="instagram" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <p className='coptyRight'>© 2025 Sensify Inc. All rights reserved.</p>
        </div>
    )
}

export default Footer
