import React, { useEffect } from "react";
import styled from "styled-components";
import Product_Cart from "./Product_Cart";
import product2 from "../assets/product2.png";
import product from "../assets/product.svg";
import sub from "../assets/sub.svg";
import mobile_Add_Counter_Icon from "../assets/mobile_Add_Counter_Icon.svg";
import mobile_Sub_Counter_Icon from "../assets/mobile_Sub_Counter_Icon.svg";
import add from "../assets/add.svg";
import outline from "../assets/outline.svg";
import { useGlobalContext } from "../context/context";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import ContinueBackBTN from "./Reuseable Components/ContinueBackButton";
import { useNavigate, Link } from "react-router-dom";
import NavHeader from "../components/Reuseable Components/NavHeader.jsx";
import logoBlack from "../assets/logo-black.png";
import right_arrow from "../assets/right_arrow.png";
import product_checkout from "../assets/product_checkout.png";
import product_checkout_2 from "../assets/product_checkout_2.png";
import NavFooter from "../components/Reuseable Components/NavFooter";
import config from "../config.js";
import axios from "axios";
import { useDispatch } from "react-redux";
import { decreaseCartItem, increaseCartItem, removeCart } from "../redux/slice/cartSlice.js";
const Checkout = ({ Id, price, Amount, ProductName, Image, PrescriptionHeading, ShelfLife, Description , Quantity}, props) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/Shipping");
    };
    const getData = (linkval, objval) => {
        navigate("/Shipping", { state: { objval } });
    };
    const handleClicktest = (link, obj) => {
        props.onClick(link, obj);
    };
    const { increase, decrease, cartItems, remove } = useGlobalContext();
    const dispatch = useDispatch()
    const removeFromCart = async (Id) => {
        dispatch(removeCart(Id))
    }

    const increaseItem = (Id) => {
        dispatch(increaseCartItem(Id))
    }

    const decreaseItem = (Id) => {
        dispatch(decreaseCartItem(Id))
    }
    return (
        <>
            <div class="row mb-lg-4 pb-lg-3 mb-md-4 pb-md-3">
                <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="product_img checkoutProductImage">
                        <img src={'https://sensify-product-images.s3.us-east-1.amazonaws.com/' +Image} width="100%"
                            alt />
                        {/* <img src={product_checkout} width="100%"
                                    alt /> */}
                    </div>
                </div>
                <div
                    class="col-lg-8 col-md-9 col-sm-12 col-12 d-flex align-items-start mt-lg-0 mt-md-4 mt-4">
                    <div class="card_title cart_sec_product">
                        <h6 class="mb-0">{ProductName}</h6>
                        <p
                            class="d-flex align-items-center text-start gap-4 mt-lg-4 mt-md-4 mt-3"><b>Price
                                :</b> <b>${Number(Quantity * Amount)?.toFixed(2)}</b></p>
                        <div
                            class="d-flex align-items-center gap-4 mt-3">
                            <p class="m-0 text-start">
                                <b>Qty. :</b>
                            </p>
                            <div class="d-flex align-items-center gap-3">
                                <div class="previous_button active" onClick={() => decreaseItem(Id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24"
                                        viewBox="0 0 24 24" fill="none">
                                        {/* <g opacity="0.25"> */}
                                        <path
                                            d="M14.4001 16.8099L9.6001 12.0099L14.4001 7.20988"
                                            stroke="#031A40"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        {/* </g> */}
                                    </svg>
                                </div>
                                <div>
                                    <p class="m-0"><b>{Quantity}</b></p>
                                </div>
                                <div class="next_button" onClick={() => increaseItem(Id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24"
                                        viewBox="0 0 24 24" fill="none">
                                        <path
                                            d="M9.5999 7.20988L14.3999 12.0099L9.5999 16.8099"
                                            stroke="#031A40"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <p
                            class="mt-4 text-start d-lg-block d-md-block d-none"><b>Shelf
                                life : {ShelfLife}</b></p>
                        <p
                            class="text-start my-3 d-lg-block d-md-block d-none">{PrescriptionHeading}</p>
                        <p
                            class="text-start m-0 d-lg-block d-md-block d-none">{Description}</p>
                        <button
                            class="btn btn-outline-primary w-auto d-flex align-items-center px-3 mt-lg-4 mt-md-4 mt-3" onClick={() => removeFromCart(Id)}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                width="20" height="20" viewBox="0 0 20 20"
                                fill="none">
                                <g clip-path="url(#clip0_3250_2380)">
                                    <path
                                        d="M1.59741 4.59614C1.59741 4.84478 1.69618 5.08323 1.872 5.25905C2.04781 5.43486 2.28627 5.53364 2.53491 5.53364H3.03491V16.6118C3.03574 17.4298 3.36101 18.214 3.93936 18.7925C4.51772 19.371 5.30192 19.6964 6.11991 19.6974H13.8799C14.6979 19.6964 15.4821 19.371 16.0605 18.7925C16.6388 18.214 16.9641 17.4298 16.9649 16.6118V5.53364H17.4649C17.7136 5.53364 17.952 5.43486 18.1278 5.25905C18.3036 5.08323 18.4024 4.84478 18.4024 4.59614C18.4024 4.3475 18.3036 4.10904 18.1278 3.93322C17.952 3.75741 17.7136 3.65864 17.4649 3.65864H14.1837V2.69114C14.1837 1.38489 13.1212 0.321762 11.8149 0.321762H8.18491C6.87866 0.321762 5.81616 1.38426 5.81616 2.69114V3.65801H2.53491C2.28627 3.65801 2.04781 3.75678 1.872 3.9326C1.69618 4.10842 1.59741 4.3475 1.59741 4.59614ZM7.69116 2.69176C7.69116 2.41864 7.91241 2.19739 8.18491 2.19739H11.8149C12.0874 2.19739 12.3087 2.41864 12.3087 2.69176V3.65864H7.69054V2.69114L7.69116 2.69176ZM4.90991 5.53364H15.0899V16.6118C15.0896 16.9326 14.962 17.2403 14.7352 17.4672C14.5084 17.6942 14.2008 17.8219 13.8799 17.8224H6.11991C5.79904 17.8219 5.49147 17.6942 5.26464 17.4672C5.03781 17.2403 4.91024 16.9326 4.90991 16.6118V5.53364Z"
                                        fill="#1D1A2F" />
                                    <path
                                        d="M7.66992 16.0955C7.91856 16.0955 8.15702 15.9967 8.33283 15.8209C8.50865 15.6451 8.60742 15.4067 8.60742 15.158V8.19801C8.60742 7.94937 8.50865 7.71092 8.33283 7.5351C8.15702 7.35929 7.91856 7.26051 7.66992 7.26051C7.42128 7.26051 7.18282 7.35929 7.00701 7.5351C6.83119 7.71092 6.73242 7.94937 6.73242 8.19801V15.158C6.73242 15.4067 6.83119 15.6451 7.00701 15.8209C7.18282 15.9967 7.42128 16.0955 7.66992 16.0955ZM12.3299 16.0955C12.5786 16.0955 12.817 15.9967 12.9928 15.8209C13.1686 15.6451 13.2674 15.4067 13.2674 15.158V8.19801C13.2674 7.94937 13.1686 7.71092 12.9928 7.5351C12.817 7.35929 12.5786 7.26051 12.3299 7.26051C12.0813 7.26051 11.8428 7.35929 11.667 7.5351C11.4912 7.71092 11.3924 7.94937 11.3924 8.19801V15.158C11.3924 15.4067 11.4912 15.6451 11.667 15.8209C11.8428 15.9967 12.0813 16.0955 12.3299 16.0955Z"
                                        fill="#1D1A2F" />
                                </g>
                                <defs>
                                    <clippath id="clip0_3250_2380">
                                        <rect width="20" height="20"
                                            fill="white"
                                            transform="translate(0 0.0098877)" />
                                    </clippath>
                                </defs>
                            </svg>
                            Remove item</button>
                    </div>
                </div>
            </div>
            <div class="mobile_seprator d-lg-none d-md-none d-block py-2">
                <hr class="my-4" />
            </div>
        </>
    );
};



export default Checkout;
