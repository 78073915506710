import { useEffect, useState } from "react";
import "./ScentAwareProProducts.css";
import axios from "axios";
import ProductComponent from "../ProductComponent/ProductComponent";
import config from "../../config";
import { useSelector } from "react-redux";

function ScentAwareProProducts() {
  const products = useSelector((state) => state.products.researchProducts)
  let link = "https://sensify-usermanual.s3.us-east-1.amazonaws.com/User+Manual+ScentAware+Olfactory+Evaluation+Kit.pdf";
  return (
    <div className="ScentAwareProProductsContainer">
      <div className="topDetail">
        <div className="heading">ScentAware Pro</div>
        <p>
          The ScentAware Pro kits offer extended shelf life and can be
          customized to suit the specific needs of a clinical study.
        </p>
      </div>
      <div className="ScentAwareProProducts">
        <div className="row m-0">
          {products.map((product, index) => {
            return <ProductComponent product={product} key={index} isLeft={true} manual={link} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default ScentAwareProProducts;
