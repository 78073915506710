import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logoBlack from "../assets/logo1.png";
import visible from "../assets/Visibility.png";
import { useNavigate, useLocation, Link } from "react-router-dom";
import eye from "../assets/eye.png";
import visa from "../assets/visa.png";
import master_card from "../assets/master_card.png";
import Swal from "sweetalert2";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import right_arrow from "../assets/right_arrow.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import config from "../config";
import PaymentForm from "./Modal/PaymentForm";
import Modal from "react-modal";
import moment from "moment";
import Footer from "./Footer/Footer";
import { StaticHeader } from "./StaticHeader/StaticHeader";

const UserProfile = () => {
  const [userName, setUserName] = useState([]);
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSubscriptionDetails, setShowSubscriptionDetails] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");
  const [profileUpdateError, setProfileUpdateError] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [activeTab, setActiveTab] = useState(""); // Default active tab
  const [showAccountOverview, setShowAccountOverview] = useState(true);
  const [suscriptionDetails, setSuscriptionDetails] = useState({});
  const [userId, setUserId] = useState(0);
  const [allCardDetails, setAllCardDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  var socilaLogIn = localStorage.getItem("socilaLogIn");
  var socialObj = JSON.parse(localStorage.getItem("socialObj"));
  var LoginEmail = localStorage.getItem("Current_Login_user");
  var stri = localStorage.getItem("local_id_store");

  const validatePasswords = () => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,16}$/;
    let isValid = true;

    setCurrentPasswordError("");
    setNewPasswordError("");
    setConfirmNewPasswordError("");

    if (!currentPassword) {
      setCurrentPasswordError("Current password is required");
      isValid = false;
    } else if (!currentPassword.match(passwordRegex)) {
      setCurrentPasswordError(
        "Password must be 8-16 characters and contain at least one number, one uppercase letter, one lowercase letter, and one special character, and cannot contain spaces."
      );
      isValid = false;
    }

    if (!newPassword) {
      setNewPasswordError("New password is required");
      isValid = false;
    } else if (!newPassword.match(passwordRegex)) {
      setNewPasswordError(
        "Password must be 8-16 characters and contain at least one number, one uppercase letter, one lowercase letter, and one special character, and cannot contain spaces."
      );
      isValid = false;
    }

    if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordError("Passwords do not match");
      isValid = false;
    }

    return isValid;
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const userDataByAccounts = JSON.parse(localStorage.getItem("socialObj"));
    const userDataByEmail = JSON.parse(localStorage.getItem("UserData"));
    if (userDataByAccounts) {
      const Name =
        userDataByAccounts.FirstName + " " + userDataByAccounts.LastName;
      setUserName(Name);
      setEmail(userDataByAccounts.EmailAddress);
      var userId = JSON.parse(localStorage.getItem("social_login_ID"));
      setUserId(userId);
    } else if (userDataByEmail) {
      setUserName(userDataByEmail.Data);
      setEmail(userDataByEmail.Data.EmailAddress);
      var userId = userDataByEmail.Data.UserId;
      setUserId(userId);
    } else {
      setUserName("");
      setEmail("");
    }
    getLastUserOrderData(userId);
    // getCardDetails(userId);
  }, []);

  const getLastUserOrderData = async (userId) => {
    try {
      const obj = {
        Url: "/get-subscription",
        UserId: parseInt(userId),
      };
      let api = `${config.BASE_URL}`;
      const response = await axios.post(api, obj);
      setIsSubscriptionActive(
        response.data.SubscriptionDetails?.Status || false
      );
      if (suscriptionDetails)
        setSuscriptionDetails(response.data.SubscriptionDetails);
    } catch (error) {}
  };
  const getCardDetails = async (userId) => {
    setLoading(true);
    try {
      const obj = {
        Url: "/get-card-details",
        UserId: parseInt(userId),
      };
      let api = `${config.BASE_URL}`;
      const response = await axios.post(api, obj);
      setAllCardDetails(response.data.CardDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteCard = async (e, id) => {
    e.preventDefault();
    try {
      const obj = {
        Url: "/delete-card-details",
        CardDetails: { UserId: userId, Id: id },
      };
      let api = `${config.BASE_URL}`;
      const response = await axios.post(api, obj);
      if (response) {
        getCardDetails(userId);
      }
    } catch (error) {}
  };
  const setAsDefault = async (e, id) => {
    e.preventDefault();
    try {
      const obj = {
        Url: "/update-card-details",
        CardDetails: { UserId: userId, Id: id, Active: true },
      };
      let api = `${config.BASE_URL}`;
      const response = await axios.post(api, obj);
      if (response) {
        getCardDetails(userId);
      }
    } catch (error) {}
  };
  const getAllOrders = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const obj = {
        Url: "/get-user-orders",
        UserId: userId,
      };
      let api = `${config.BASE_URL}`;
      const response = await axios.post(api, obj);
      setOrderDetails(response.data?.OrderList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const ClickLogout = () => {
    if (localStorage.getItem("local_id_store") > 0) {
      Swal.fire({
        icon: "danger",
        //   title: 'Sorry!',
        text: `Are you sure you want to logout?`,
        showConfirmButton: true,
        confirmButtonText: "Logout",
        showCancelButton: true,
      }).then((result) => {
        if (result["isConfirmed"]) {
          handleClickout();
        } else {
        }
      });
      // localStorage.removeItem("social_login_ID");
      // localStorage.removeItem("socialObj");
      // localStorage.removeItem("socilaLogIn");
      // localStorage.removeItem("UserData");
      // localStorage.removeItem('orderNumber');
      // localStorage.removeItem('shippingFormData');
      // localStorage.removeItem('BillingIntelliVista');
    } else if (socilaLogIn == "true") {
      Swal.fire({
        icon: "danger",
        text: `You were logged in by ${socialObj?.SocialMedia} do you want to logout?`,
        showConfirmButton: true,
        confirmButtonText: "Logout",
        showCancelButton: true,
      }).then((result) => {
        if (result["isConfirmed"]) {
          window.localStorage.clear();
          navigate("/");
        } else {
        }
      });
    }
  };
  const onLogoutSuccess = (response) => {
    console.log(response, "onLogoutSuccess");
    Swal.fire({
      icon: "danger",
      text: `You were logged in by ${socialObj?.SocialMedia} do you want to logout?`,
      showConfirmButton: true,
      confirmButtonText: "Logout",
      showCancelButton: true,
    }).then((result) => {
      if (result["isConfirmed"]) {
        window.localStorage.clear();
        navigate("/");
      } else {
        console.log("cancelled");
      }
    });
    // setShowLogoutBtn(false);
  };
  const handleClickout = () => {
    navigate("/");
    window.localStorage.clear();
    // window.localStorage.removeItem("local_id_store");
    window.location.reload();
  };

  const handleUpdateProfile = async () => {
    setCurrentPasswordError(null);
    setNewPasswordError(null);
    setConfirmNewPasswordError(null);
    setProfileUpdateError(null);
    if (!validatePasswords()) {
      return;
    }
    setLoad(true);
    try {
      const updateData = {
        Url: "/update-user-profile",
        CurrentPassword: currentPassword,
        NewPassword: newPassword,
        UserProfile: {
          Email: email,
          UserName: userName.UserName ? userName.UserName : userName,
          Id: userName.UserId,
        },
      };
      let api = `${config.BASE_URL}`;
      const response = await axios.post(api, updateData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data?.IsSuccess === false) {
        setProfileUpdateError(response.data?.Message);
      } else {
        setCurrentPassword(null);
        setNewPassword(null);
        setConfirmNewPassword(null);
        toggleResetPassword();
      }
      setLoad(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      setLoad(false);
    }
  };

  const toggleResetPassword = () => {
    setIsResetPassword(!isResetPassword);
    setCurrentPassword(null);
    setNewPassword(null);
    setConfirmNewPassword(null);
    setCurrentPasswordError(null);
    setNewPasswordError(null);
    setConfirmNewPasswordError(null);
    setProfileUpdateError(null);
  };

  const toggleSubscriptionDetails = () => {
    setShowSubscriptionDetails(!showSubscriptionDetails);
  };

  const handleMobileTab = (tab) => {
    setShowAccountOverview(false);
    switch (tab) {
      case "Profile":
        setActiveTab("Profile");
        break;
      case "Subscription":
        setActiveTab("Subscription");
        break;
      case "Payment":
        setActiveTab("Payment");
        break;
      case "Orders":
        setActiveTab("Orders");
        break;
      case "Settings":
        setActiveTab("Settings");
        break;
      default:
        break;
    }
  };

  return (
    <body className="renaware" style={{ backgroundColor: "#031A40" }}>
      {loading ? (
        <div className="w-100 page_loader align-items-center d-flex justify-content-center">
          <h1
            class="loader"
            style={{
              // color: "#101A36",
              margin: "0",
              width: "30px",
              height: "30px",
              backdropFilter: "blur(8px)",
            }}
          ></h1>
        </div>
      ) : (
        ""
      )}

      <StaticHeader />
      <section class="get_the_app user_profile d-lg-flex d-md-flex d-flex">
        <div class="container">
          <div class="app_card account_card">
            <div class="row m-0">
              <div class="col-lg-2 col-md-3 col-sm-12 col-12 px-3 d-flex align-items-start">
                <ul
                  class="nav nav-tabs flex-column d-lg-flex d-md-flex d-none gap-4"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link p-2 active"
                      id="Accountoverview-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Accountoverview"
                      type="button"
                      role="tab"
                      aria-controls="Accountoverview"
                      aria-selected="true"
                    >
                      Account overview
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link p-2"
                      id="Profilesettings-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Profilesettings"
                      type="button"
                      role="tab"
                      aria-controls="Profilesettings"
                      aria-selected="false"
                    >
                      Profile settings
                    </button>
                  </li>
                  {/* <li class="nav-item" role="presentation">
                    <button
                      class="nav-link p-2"
                      id="SubscriptionDetails-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#SubscriptionDetails"
                      type="button"
                      role="tab"
                      aria-controls="SubscriptionDetails"
                      aria-selected="false"
                    >
                      Subscription Details
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link p-2"
                      id="Paymentmethods-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Paymentmethods"
                      type="button"
                      role="tab"
                      aria-controls="Paymentmethods"
                      aria-selected="false"
                      onClick={() => getCardDetails(userId)}
                    >
                      Payment methods
                    </button>
                  </li> */}
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link p-2"
                      id="Yourorders-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Yourorders"
                      type="button"
                      role="tab"
                      aria-controls="Yourorders"
                      aria-selected="false"
                      onClick={(e) => getAllOrders(e)}
                    >
                      Your orders
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link p-2"
                      id="SecuritySettings-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#SecuritySettings"
                      type="button"
                      role="tab"
                      aria-controls="SecuritySettings"
                      aria-selected="false"
                    >
                      Security Settings
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    {/* <button class="nav-link p-2" id="Logout-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#Logout" type="button"
                                    role="tab" aria-controls="Logout"
                                    aria-selected="false">Log out</button> */}
                    {socialObj?.SocialMedia === "Google" ? (
                      <GoogleLogout
                        clientId="251997598489-2en61bset3ti5uakmnc5370fvma3v0sl.apps.googleusercontent.com"
                        buttonText="Logout"
                        onLogoutSuccess={onLogoutSuccess}
                        render={(renderProps) => (
                          <button
                            class="nav-link p-2"
                            id="Logout-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Logout"
                            type="button"
                            role="tab"
                            aria-controls="Logout"
                            aria-selected="false"
                            onClick={renderProps.onClick}
                          >
                            Log out
                          </button>
                        )}
                      />
                    ) : (
                      <button
                        onClick={ClickLogout}
                        class="nav-link p-2"
                        id="Logout-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Logout"
                        type="button"
                        role="tab"
                        aria-controls="Logout"
                        aria-selected="false"
                      >
                        Log out
                      </button>
                    )}
                  </li>
                </ul>
              </div>
              <div class="col-lg-10 col-md-9 col-sm-12 p-0 col-12 d-flex">
                <div class="profile_border h-100 w-auto d-lg-block d-md-block d-none">
                  <hr class="mx-4 my-0" />
                </div>
                <div class="tab-content w-100" id="myTabContent">
                  <div
                    className={`tab-pane fade ${
                      showAccountOverview ? "show active" : ""
                    }`}
                    id="Accountoverview"
                    role="tabpanel"
                    aria-labelledby="Accountoverview-tab"
                  >
                    <div class="profile_card_innner d-lg-block d-md-block d-none  py-lg-4 py-md-4 h-100">
                      <h3 class="mb-4">
                        Welcome{" "}
                        {userName.UserName ? userName.UserName : userName}!
                      </h3>
                      <div class="d-flex align-items-center py-2">
                        <label class="m-0">User name :</label>
                        <p class="m-0 px-3">
                          {userName.UserName ? userName.UserName : userName}
                        </p>
                      </div>
                      <div class="d-flex align-items-center py-2 mt-3">
                        <label class="m-0">E-mail :</label>
                        <p class="m-0 px-3">{email}</p>
                      </div>
                    </div>

                    <div className="card_title user_profile_list d-lg-none d-md-none d-block ">
                      <h6 className="mb-3 text-dark px-2">
                        Welcome{" "}
                        {userName.UserName ? userName.UserName : userName}!
                      </h6>
                      <ul className="pt-3 d-flex flex-column gap-3">
                        <li onClick={() => handleMobileTab("Profile")}>
                          Profile settings
                        </li>
                        <li onClick={() => handleMobileTab("Subscription")}>
                          Subscription Details
                        </li>
                        <li
                          onClick={() => {
                            handleMobileTab("Payment");
                            getCardDetails(userId);
                          }}
                        >
                          Payment methods
                        </li>
                        <li
                          onClick={(e) => {
                            handleMobileTab("Orders");
                            getAllOrders(e);
                          }}
                        >
                          Your orders
                        </li>
                        <li onClick={() => handleMobileTab("Settings")}>
                          Security Settings
                        </li>
                        {/* <li onClick={() => handleMobileTab('Logout')}>Log out</li> */}
                        <li class="nav-item" role="presentation">
                          {socialObj?.SocialMedia === "Google" ? (
                            <GoogleLogout
                              clientId="251997598489-2en61bset3ti5uakmnc5370fvma3v0sl.apps.googleusercontent.com"
                              buttonText="Logout"
                              onLogoutSuccess={onLogoutSuccess}
                              render={(renderProps) => (
                                <li
                                  class="nav-link p-2"
                                  id="Logout-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#Logout"
                                  type="button"
                                  role="tab"
                                  aria-controls="Logout"
                                  aria-selected="false"
                                  onClick={renderProps.onClick}
                                >
                                  Log out
                                </li>
                              )}
                            />
                          ) : (
                            <li onClick={ClickLogout}>Log out</li>
                          )}
                        </li>
                        {/* </li> */}
                      </ul>
                    </div>
                  </div>
                  <div
                    className={`tab-pane w-100 fade ${
                      activeTab === "Profile" ? "show active" : ""
                    }`}
                    id="Profilesettings"
                    role="tabpanel"
                    aria-labelledby="Profilesettings-tab"
                  >
                    <div class="profile_card_innner w-100  py-lg-4 py-md-4 h-100">
                      <h3 class="mb-4 pb-lg-0 pb-md-0 pb-3">
                        <img
                          src={right_arrow}
                          className="me-1 d-lg-none d-md-none d-inline-block"
                          onClick={() => {
                            setActiveTab(null);
                            setShowAccountOverview(true);
                          }}
                          alt=""
                        />{" "}
                        Profile details
                      </h3>
                      <div class="d-flex flex-lg-row flex-md-row flex-column align-items-lg-center align-items-md-center align-items-start">
                        <label class="mb-lg-0 mb-md-0 mb-2">User name :</label>
                        <input
                          type="text"
                          name="userName"
                          value={
                            userName.UserName ? userName.UserName : userName
                          }
                          placeholder="Anika Rhiel Madsen"
                          id="userName"
                          readOnly
                        />
                      </div>
                      <div class="d-flex flex-lg-row flex-md-row flex-column align-items-lg-center align-items-md-center align-items-start mt-4">
                        <label class="mb-lg-0 mb-md-0 mb-2">E-mail :</label>
                        <input
                          type="email"
                          name="email"
                          value={email}
                          placeholder="maadsen@outlook.com"
                          id="email"
                          readOnly
                        />
                      </div>
                      {!isResetPassword && (
                        <>
                          <div class="d-flex flex-lg-row flex-md-row flex-column align-items-lg-center align-items-md-center align-items-start mt-4">
                            <label class="mb-lg-0 mb-md-0 mb-2">
                              Current Password :
                            </label>
                            <div class="position-relative password_field w-100">
                              <input
                                type="password"
                                name
                                placeholder="----------"
                                id
                                readOnly
                              />
                              <div class="eye">
                                <img src={visible} />
                              </div>
                            </div>
                          </div>
                          <button
                            class="btn btn-primary py-lg-2 py-md-3 py-3 mt-4 px-4 w-auto d-lg-block d-md-block d-none"
                            onClick={toggleResetPassword}
                          >
                            Reset password
                          </button>
                          <button
                            class="btn btn-primary py-lg-2 py-md-3 py-3 mt-4 px-4 w-100 d-lg-none d-md-none d-block"
                            onClick={toggleResetPassword}
                          >
                            Reset password
                          </button>
                        </>
                      )}

                      {isResetPassword && (
                        <div class="mt-4 d-flex flex-lg-row flex-md-row flex-column align-items-lg-start align-items-md-start align-items-start w-100 gap-lg-4 gap-md-4">
                          <div class="pssword_field">
                            <div class="d-flex flex-lg-row flex-md-row flex-column align-items-lg-start align-items-md-start align-items-start">
                              <label class="mb-lg-0 mb-md-0 mb-2 pt-lg-2 pt-md-2">
                                Current Password :
                              </label>
                              <div class="position-relative password_field w-100">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  name="currentPassword"
                                  value={currentPassword}
                                  onChange={(e) =>
                                    setCurrentPassword(e.target.value)
                                  }
                                  placeholder="----------"
                                  id
                                />
                                <div class="eye">
                                  {showPassword ? (
                                    <VisibilityIcon
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      className="password-icon"
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      className="password-icon"
                                    />
                                  )}
                                </div>
                                {currentPasswordError && (
                                  <div className="text-danger">
                                    {currentPasswordError}
                                  </div>
                                )}
                                {/* {profileUpdateError && <div className="text-danger">{'Current password is wrong'}</div>} */}
                              </div>
                            </div>
                            {/* <div
                              class="d-flex  align-items-center "> */}
                            <div class="w-100 d-flex flex-lg-row flex-md-row flex-column align-items-lg-start align-items-md-start align-items-start mt-3">
                              <label class="mb-lg-0 mb-md-0 mb-2 pt-lg-2 pt-md-2">
                                New Password :
                              </label>
                              <div class="position-relative password_field w-100">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  name="newPassword"
                                  value={newPassword}
                                  onChange={(e) =>
                                    setNewPassword(e.target.value)
                                  }
                                  placeholder="----------"
                                  id
                                />
                                <div class="eye">
                                  {showPassword ? (
                                    <VisibilityIcon
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      className="password-icon"
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      className="password-icon"
                                    />
                                  )}
                                </div>
                                {newPasswordError && (
                                  <div className="text-danger">
                                    {newPasswordError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="w-100 mt-3 d-flex flex-lg-row flex-md-row flex-column align-items-lg-center align-items-md-center align-items-start">
                              <label class="mb-lg-0 mb-md-0 mb-2 pt-lg-2 pt-md-2 d-lg-none d-md-none d-flex">
                                Confirm new password :{" "}
                              </label>
                              <label className="d-lg-flex d-md-flex d-none m-0"></label>
                              <div class="w-100">
                                <input
                                  type="password"
                                  name="confirmNewPassword"
                                  value={confirmNewPassword}
                                  onChange={(e) =>
                                    setConfirmNewPassword(e.target.value)
                                  }
                                  placeholder
                                  id="confirmNewPassword"
                                />
                                {confirmNewPasswordError && (
                                  <div className="text-danger">
                                    {confirmNewPasswordError}
                                  </div>
                                )}

                                <div class="password_remember d-lg-none d-md-none d-block mt-3 pb-4">
                                  <label style={{ fontWeight: "300" }}>
                                    Remember, a strong password is :
                                  </label>
                                  <ul>
                                    <li>8-16 characters long.</li>
                                    <li>
                                      Contains a mix of upper and lower case
                                      letters.
                                    </li>
                                    <li>
                                      Contains numeric and special characters.
                                    </li>
                                    <li>Not your birthdate.</li>
                                  </ul>
                                </div>
                                <div class="w-100 mt-3 d-flex flex-lg-row flex-md-row flex-column gap-3">
                                  <button
                                    class="btn btn-primary py-lg-2 py-md-3 py-3 px-4 w-auto"
                                    onClick={handleUpdateProfile}
                                  >
                                    {load ? (
                                      <div className="w-100 align-items-center d-flex justify-content-center">
                                        <h1
                                          class="loader"
                                          disabled={load}
                                          style={{
                                            // color: "#101A36",
                                            margin: "-5px",
                                            width: "30px",
                                            height: "30px",
                                          }}
                                        ></h1>
                                      </div>
                                    ) : (
                                      "Save"
                                    )}
                                  </button>
                                  <button
                                    class="btn btn-outline-primary py-lg-2 py-md-3 py-3 px-4 w-auto"
                                    onClick={toggleResetPassword}
                                  >
                                    Cancel
                                  </button>
                                </div>
                                {profileUpdateError && (
                                  <div className="text-danger">
                                    {"Please Enter valid details!"}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="password_remember pt-5 mt-2 d-lg-block d-md-block d-none">
                            <label style={{ fontWeight: "300" }}>
                              Remember, a strong password is :
                            </label>
                            <ul>
                              <li>8-16 characters long.</li>
                              <li>
                                Contains a mix of upper and lower case letters.
                              </li>
                              <li>Contains numeric and special characters.</li>
                              <li>Not your birthdate.</li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`tab-pane w-100 fade ${
                      activeTab === "Subscription" ? "show active" : ""
                    }`}
                    id="SubscriptionDetails"
                    role="tabpanel"
                    aria-labelledby="SubscriptionDetails-tab"
                  >
                    <div class="profile_card_innner  py-lg-4 py-md-4 w-100 h-100">
                      <h3 class="mb-lg-4 md-md-4 mb-0">
                        <img
                          src={right_arrow}
                          className="me-1 d-lg-none d-md-none d-inline-block"
                          onClick={() => {
                            setActiveTab(null);
                            setShowAccountOverview(true);
                          }}
                          alt=""
                        />
                        Subscription details
                      </h3>

                      {!isSubscriptionActive && (
                        <>
                          <div class="d-flex align-items-center my-lg-0 my-md-0 my-5 justify-content-lg-start justify-content-md-start justify-content-center">
                            <label class="m-0" style={{ fontWeight: "400" }}>
                              You haven’t subscribed to any plans yet.{" "}
                            </label>
                          </div>
                          <button
                            class="btn btn-primary w-auto mt-4 d-lg-block d-md-block d-none"
                            onClick={() => navigate("/IntelliVista")}
                          >
                            See all plans
                          </button>
                          <button
                            class="btn btn-primary w-100 d-lg-none d-md-none d-block"
                            onClick={() => navigate("/IntelliVista")}
                          >
                            See all plans
                          </button>
                        </>
                      )}

                      {isSubscriptionActive && (
                        <>
                          <div className="row g-lg-4 g-md-4 g-4 pt-lg-4 pt-md-4 pt-5">
                            <div className="col-lg-2 col-md-4 col-sm-3 col-3 d-flex align-items-center">
                              <div className="card_title">
                                <p
                                  className="m-0 text-start  text-nowrap"
                                  style={{ fontWeight: 500 }}
                                >
                                  Status :
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-10 col-md-8 col-sm-9 col-9">
                              <button className="btn btn-outline-primary subscription_status px-4 py-2 w-auto">
                                Active
                              </button>
                            </div>

                            <div className="col-12 d-lg-none d-md-none d-block">
                              <hr
                                className="m-0"
                                style={{
                                  backgroundColor: "#F1F2F6",
                                  opacity: 1,
                                  border: "none",
                                }}
                              />
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-12 col-12 d-flex align-items-start ">
                              <div className="card_title">
                                <p
                                  className="mb-lg-2 mb-md-2 mb-0 text-start"
                                  style={{ fontWeight: 500 }}
                                >
                                  Plan :
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-10 mt-lg-4 mt-md-4 mt-3 col-md-8 col-sm-12 col-12">
                              <div className="card_title">
                                <p className="m-0 text-start">
                                  <b>IntelliVista-Basic at $4,995/year</b>
                                </p>
                                <ul>
                                  <li>1 administrator account login.</li>
                                  {/* <li>10,000 participants.</li> */}
                                  <li>Unlimited trials.</li>
                                </ul>
                              </div>
                            </div>

                            <div className="col-12 d-lg-none d-md-none d-block">
                              <hr
                                className="m-0"
                                style={{
                                  backgroundColor: "#F1F2F6",
                                  opacity: 1,
                                  border: "none",
                                }}
                              />
                            </div>

                            {/* <div className="col-lg-2 col-md-4 col-sm-12 col-12 d-flex align-items-start ">
                              <div className="card_title">
                                <p className="mb-2 text-start" style={{ fontWeight: 500 }}>Participants :</p>
                              </div>
                            </div>
                            <div className="col-lg-10 col-md-8 col-sm-12 col-12">
                              <div className="card_title">
                                <p className="mb-2 text-start" style={{ fontWeight: 500 }}>9,568 / 10,000 currently active.</p>
                              </div>
                            </div> */}

                            <div className="col-lg-2 col-md-4 col-sm-12 col-12 d-flex align-items-start ">
                              <div className="card_title">
                                <p
                                  className="mb-lg-2 mb-md-2 mb-0 text-start"
                                  style={{ fontWeight: 500 }}
                                >
                                  Administrator :
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-10 col-md-8 col-sm-12 col-12  mt-lg-4 mt-md-4 mt-3">
                              <div className="card_title">
                                {/* <p className="mb-2 text-start" style={{ fontWeight: 500 }}>1 / 1 currently active.</p> */}
                                <p
                                  className="mb-0 text-start"
                                  style={{ fontWeight: 500 }}
                                >
                                  {suscriptionDetails.Administrator}/1 currently
                                  active.
                                </p>
                              </div>
                            </div>

                            <div className="col-12 d-lg-none d-md-none d-block">
                              <hr
                                className="m-0"
                                style={{
                                  backgroundColor: "#F1F2F6",
                                  opacity: 1,
                                  border: "none",
                                }}
                              />
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-12 col-12 d-flex align-items-start ">
                              <div className="card_title">
                                <p
                                  className="mb-lg-2 mb-md-2 mb-0 text-start"
                                  style={{ fontWeight: 500 }}
                                >
                                  Valid till :
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-10 col-md-8 col-sm-12 col-12  mt-lg-4 mt-md-4 mt-3">
                              <div className="card_title">
                                {/* <p className="mb-2 text-start" style={{ fontWeight: 500 }}>January 18 2024, (364 days) </p> */}
                                <p
                                  className="mb-0 text-start"
                                  style={{ fontWeight: 500 }}
                                >
                                  {moment(suscriptionDetails.ExpiryDate).format(
                                    "MMMM DD YYYY"
                                  )}
                                  , (364 days){" "}
                                </p>
                              </div>
                            </div>

                            {/* <div className="col-12 d-lg-flex d-md-flex d-flex flex-lg-row flex-md-row flex-column mt-lg-4 mt-md-4 mt-5">
                              <button className="btn btn-outline-primary w-auto">
                                Cancel subscription
                              </button>
                            </div> */}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={`tab-pane w-100 fade ${
                      activeTab === "Payment" ? "show active" : ""
                    }`}
                    id="Paymentmethods"
                    role="tabpanel"
                    aria-labelledby="Paymentmethods-tab"
                  >
                    <div class="profile_card_innner py-lg-4 py-md-4 w-100 h-100">
                      <div class="d-flex align-items-center gap-3 justify-content-between">
                        <h3 class="mb-4 pb-lg-0 pb-md-0 pb-3">
                          <img
                            src={right_arrow}
                            className="me-1 d-lg-none d-md-none d-inline-block"
                            onClick={() => {
                              setActiveTab(null);
                              setShowAccountOverview(true);
                            }}
                            alt=""
                          />{" "}
                          Saved payment methods
                        </h3>
                        <button
                          class="btn btn-primary d-lg-block d-md-block d-none w-auto"
                          onClick={openModal}
                        >
                          Add payment method
                        </button>
                      </div>
                      {allCardDetails.length === 0 ? (
                        <div className="text-center mt-4">
                          <p>No payment cards found.</p>
                        </div>
                      ) : (
                        allCardDetails.map((card) => (
                          <div
                            key={card.Id}
                            className="w-100 p-lg-4 p-md-4 p-4 payment_card mt-4 mb-lg-0 mb-md-0 mb-3"
                          >
                            <div className="d-flex align-items-lg-center align-items-md-center align-items-start flex-lg-row flex-md-row flex-column justify-content-between">
                              <div className="card_title">
                                <div className="d-flex align-items-lg-center align-items-md-center align-items-start flex-lg-row flex-md-row flex-column gap-3">
                                  <h6
                                    style={{ fontWeight: "700" }}
                                    className="mb-0"
                                  >
                                    {card.Name}
                                  </h6>
                                </div>
                                <p className="mb-1 mt-4 text-start">
                                  Credit card ending in {card.CardNumber}
                                </p>
                                <p className="m-0 text-start">
                                  Expires on: {card.ExpiryDate}
                                </p>
                              </div>
                              <div className="button d-flex flex-column gap-3 mt-lg-0 mt-md-0 mt-4">
                                {card.Active ? (
                                  <button
                                    className="btn btn-outline-primary disable mt-lg-0 mt-md-0 mt-4 w-auto py-2"
                                    disabled
                                  >
                                    Default
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary d-block w-auto py-2"
                                      style={{ minWidth: "160px" }}
                                      onClick={(e) => setAsDefault(e, card.Id)}
                                    >
                                      Set as default
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary d-block w-auto py-2"
                                      style={{ minWidth: "160px" }}
                                      onClick={(e) => deleteCard(e, card.Id)}
                                    >
                                      Remove card
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      )}

                      <button
                        class="btn btn-primary d-lg-none d-md-none d-block   py-3 px-4 mt-4 w-100"
                        onClick={openModal}
                      >
                        Add payment method
                      </button>
                    </div>
                  </div>
                  <div
                    className={`tab-pane w-100 fade ${
                      activeTab === "Orders" ? "show active" : ""
                    }`}
                    id="Yourorders"
                    role="tabpanel"
                    aria-labelledby="Yourorders-tab"
                  >
                    <div class="profile_card_innner py-lg-4 py-md-4 h-100">
                      {/* <h3 class="mb-4">Your orders</h3> */}
                      <h3 class="mb-4 pb-lg-0 pb-md-0 pb-3">
                        <img
                          src={right_arrow}
                          className="me-1 d-lg-none d-md-none d-inline-block"
                          onClick={() => {
                            setActiveTab(null);
                            setShowAccountOverview(true);
                          }}
                          alt=""
                        />{" "}
                        Your orders
                      </h3>
                      <div class="order_table user_table w-100 overflow-auto">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Description</th>
                              <th>qty.</th>
                              <th>Amount</th>
                              <th className="text-nowrap">payment mode</th>
                              {/* <th>Invoice</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetails.length == 0 ? (
                              <tr>
                                <td colSpan="5">No order found</td>
                              </tr>
                            ) : (
                              orderDetails.map((card) => (
                                <tr key={card.orderId}>
                                  <td>
                                    {moment(card.CreatedDate).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                  <td
                                    className="text-start"
                                    style={{ minWidth: "220px" }}
                                  >
                                    {card.orderDetailResponse.ProductName}
                                  </td>
                                  <td>{card.orderDetailResponse.Quantity}</td>
                                  <td>{card.orderDetailResponse.Price}</td>
                                  <td>{card.DeliveryType}</td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane w-100 fade ${
                      activeTab === "Settings" ? "show active" : ""
                    }`}
                    id="SecuritySettings"
                    role="tabpanel"
                    aria-labelledby="SecuritySettings-tab"
                  >
                    <div class="profile_card_innner py-lg-4 py-md-4 w-100 h-100">
                      <h3 class="mb-4 pb-lg-0 pb-md-0 pb-3">
                        <img
                          src={right_arrow}
                          className="me-1 d-lg-none d-md-none d-inline-block"
                          onClick={() => {
                            setActiveTab(null);
                            setShowAccountOverview(true);
                          }}
                          alt=""
                        />{" "}
                        Security settings
                      </h3>
                      <div class="d-lg-flex d-md-flex d-none align-items-center">
                        <label
                          class="m-0 d-flex align-items-center gap-3"
                          style={{ fontWeight: "700" }}
                        >
                          <div class="checkbox-wrapper-2 d-flex align-items-center">
                            <input
                              type="checkbox"
                              class="authenticate_check"
                              checked={isCheckboxChecked}
                              onChange={(e) =>
                                setIsCheckboxChecked(e.target.checked)
                              }
                            />
                          </div>
                          Enable Two-Factor Authentication (2FA) :
                        </label>
                      </div>
                      <div class="d-lg-none d-md-none d-flex align-items-center">
                        <label
                          class="m-0 d-flex align-items-center w-100 justify-content-between gap-3"
                          style={{ fontWeight: "700" }}
                        >
                          Enable Two-Factor Authentication (2FA) :
                          <div class="checkbox-wrapper-2 d-flex align-items-center">
                            <input
                              type="checkbox"
                              class="authenticate_check"
                              checked={isCheckboxChecked}
                              onChange={(e) =>
                                setIsCheckboxChecked(e.target.checked)
                              }
                            />
                          </div>
                        </label>
                      </div>
                      <div class="password_remember pt-lg-0 pt-md-0 pt-3 mt-4">
                        <ul>
                          <li>
                            Two-Factor Authentication (2FA) adds an extra
                            security step to your login process.
                          </li>
                          <li>
                            After entering your password, you'll also need to
                            verify your identity using a second
                            <br /> code sent on your phone or e-mail.{" "}
                          </li>
                          <li>
                            This makes it much harder for unauthorized users to
                            access your account, even if they
                            <br /> know your password, providing an essential
                            layer of security against data breaches
                            <br /> and identity theft.
                          </li>
                        </ul>
                      </div>

                      <div class="d-flex align-items-lg-center align-items-md-center align-items-start flex-lg-row flex-md-row flex-column pt-lg-0 pt-md-0 pt-3 pb-lg-0 pb-md-0 pb-3 mt-4">
                        <label class="m-0" disabled={!isCheckboxChecked}>
                          E-mail :
                        </label>
                        <input
                          type="mail"
                          name="Email"
                          placeholder="maadsen@outlook.com"
                          id="Email"
                          disabled={!isCheckboxChecked}
                        />
                      </div>
                      <button
                        class="btn btn-outline-primary d-lg-block d-md-block d-none w-auto mt-4 py-2"
                        disabled={!isCheckboxChecked}
                      >
                        Change e-mail address
                      </button>
                      <button
                        class="btn btn-outline-primary d-lg-none d-md-none d-block w-100 mt-4 py-3"
                        disabled={!isCheckboxChecked}
                      >
                        Change e-mail address
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="login_modal"
      >
        <PaymentForm
          closeModal={closeModal}
          userId={userId}
          allCardDetails={allCardDetails}
          getCardDetails={getCardDetails}
        />
      </Modal>

      {/* <section className="d-lg-none d-md-none user_profile_mobile d-none bg-white px-3 py-4">
        <div className="container py-2">
          <div className="row">
            <div className="col-12">
              <div className="card_title user_profile_list">
                {activeSection === null ? (
                  <>
                    <h6 className="mb-3 text-dark px-2">Welcome {userName.UserName ? userName.UserName : userName}!</h6>
                    <ul className="pt-3">
                      <li onClick={() => handleItemClick(0)}>Profile settings</li>
                      <li onClick={() => handleItemClick(1)}>Subscription Details</li>
                      <li onClick={() => handleItemClick(2)}>Payment methods</li>
                      <li onClick={() => handleItemClick(3)}>Your orders</li>
                      <li onClick={() => handleItemClick(4)}>Security Settings</li>

                      <li>
                        {socialObj?.SocialMedia === "Google" ? (
                          <GoogleLogout
                            clientId="251997598489-2en61bset3ti5uakmnc5370fvma3v0sl.apps.googleusercontent.com"
                            buttonText="Logout"
                            onLogoutSuccess={onLogoutSuccess}
                            render={(renderProps) => (
                              <li
                                // class="nav-link p-2" id="Logout-tab"
                                // data-bs-toggle="tab"
                                // data-bs-target="#Logout" type="button"
                                // role="tab" aria-controls="Logout"
                                // aria-selected="false"
                                onClick={renderProps.onClick}
                              >
                                Log out
                              </li>
                            )}
                          />
                        ) : (
                          <li
                            onClick={ClickLogout}
                          >
                            Log out
                          </li>
                        )}
                      </li>
                    </ul>
                  </>
                ) : null}
                <div>

                  {activeSection === 0 && (
                    <div class="profile_card_innner py-4 h-100">
                      <h3 class="mb-4 pb-3">
                        <img src={right_arrow} className="me-1" onClick={() => setActiveSection(null)} alt="" />
                        Profile details</h3>
                      <div
                        class="d-flex align-items-center flex-column">
                        <label class="m-0 w-100">User name
                          :</label>
                        <input type="text" className="mb-0" name="userName" value={userName.UserName ? userName.UserName : userName} placeholder="Anika Rhiel Madsen" id="userName" />
                      </div>
                      <div
                        class="d-flex align-items-center flex-column mt-4">
                        <label class="m-0 w-100">E-mail :</label>
                        <input type="email" className="mb-0 mt-2" name="email" value={email} placeholder="maadsen@outlook.com" id="email" />
                      </div>
                      <div
                        class="d-flex align-items-center flex-column mt-4">
                        <label class="m-0 w-100">Password
                          :</label>
                        <div
                          class="position-relative mt-2 mb-3 password_field w-100">
                          <input type="password"
                            name className="mb-0"
                            placeholder="----------"
                            id />
                          <div class="eye">
                            <img
                              src={visible} />
                          </div>
                        </div>
                      </div>
                      <button
                        class="btn btn-primary py-3 mt-4 px-4" onClick={() => navigate("/forget_Password")}>Reset
                        password</button>
                    </div>
                  )}

                  {activeSection === 1 && (
                    <div class="profile_card_innner py-4 h-100">
                      <h3 class="mb-0">
                        <img src={right_arrow} className="me-1" onClick={() => setActiveSection(null)} alt="" />Subscription details</h3>
                      <div
                        class="d-flex flex-column align-items-center gap-3 justify-content-between">
                        <p class="my-5 text-center">You haven’t subscribed to any plans yet.</p>
                        <button
                          class="btn btn-primary w-100 py-3 px-4" onClick={() => navigate('/IntelliVista')}>See
                          all plans</button>
                      </div>

                      <div className="row g-lg-4 g-md-3 g-3 pt-4">
                        <div className="col-lg-2 col-md-4 col-sm-5 col-5 d-flex align-items-center">
                          <div className="card_title">
                            <p className="m-0 text-start" style={{ fontWeight: 500 }}>Status :</p>
                          </div>
                        </div>
                        <div className="col-lg-10 col-md-8 col-sm-7 col-7">
                          <button className="btn btn-outline-primary subscription_status px-4 py-2 w-auto" onClick={toggleSubscriptionDetails}>
                            Active
                          </button>
                        </div>

                        {showSubscriptionDetails && (
                          <>
                            <div className="col-lg-2 col-md-4 col-sm-12 col-12 d-flex align-items-start ">
                              <div className="card_title">
                                <p className="mb-2 text-start" style={{ fontWeight: 500 }}>Plan :</p>
                              </div>
                            </div>
                            <div className="col-lg-10 col-md-8 col-sm-12 col-12">
                              <div className="card_title">
                                <p className="m-0 text-start"><b>IntelliVista-Basic at $4,995/year</b></p>
                                <ul>
                                  <li>1 administrator account login.</li>
                                  <li>10,000 participants.</li>
                                  <li>Unlimited trials.</li>
                                </ul>
                              </div>
                            </div>


                            <div className="col-lg-2 col-md-4 col-sm-12 col-12 d-flex align-items-start ">
                              <div className="card_title">
                                <p className="mb-2 text-start" style={{ fontWeight: 500 }}>Participants :</p>
                              </div>
                            </div>
                            <div className="col-lg-10 col-md-8 col-sm-12 col-12">
                              <div className="card_title">
                                <p className="mb-2 text-start" style={{ fontWeight: 500 }}>9,568 / 10,000 currently active.</p>
                              </div>
                            </div>


                            <div className="col-lg-2 col-md-4 col-sm-12 col-12 d-flex align-items-start ">
                              <div className="card_title">
                                <p className="mb-2 text-start" style={{ fontWeight: 500 }}>Administrator :</p>
                              </div>
                            </div>
                            <div className="col-lg-10 col-md-8 col-sm-12 col-12">
                              <div className="card_title">
                                <p className="mb-2 text-start" style={{ fontWeight: 500 }}>1 / 1 currently active.</p>
                              </div>
                            </div>


                            <div className="col-lg-2 col-md-4 col-sm-12 col-12 d-flex align-items-start ">
                              <div className="card_title">
                                <p className="mb-2 text-start" style={{ fontWeight: 500 }}>Valid till :</p>
                              </div>
                            </div>
                            <div className="col-lg-10 col-md-8 col-sm-12 col-12">
                              <div className="card_title">
                                <p className="mb-2 text-start" style={{ fontWeight: 500 }}>January 18 2024, (364 days) </p>
                              </div>
                            </div>

                            <div className="col-12">
                              <button className="btn btn-outline-primary  py-3 px-4">
                                Cancel subscription
                              </button>
                            </div>
                          </>
                        )}
                      </div>

                    </div>
                  )}

                  {activeSection === 2 && (
                    <div class="profile_card_innner  py-4 h-100">
                      <h3 class="mb-4 pb-3">
                        <img src={right_arrow} className="me-1" onClick={() => setActiveSection(null)} alt="" />Saved payment methods</h3>
                      <div
                        class="w-100 p-lg-4 p-md-4 p-4 payment_card mt-4">
                        <div
                          class="d-flex align-items-start flex-column justify-content-start">
                          <div class="card_title pb-3">
                            <div
                              class="d-flex align-items-start flex-column gap-3">
                              <h6
                                style={{ fontWeight: '700' }}
                                class=" mb-0">Anika
                                Maadsen</h6>
                              <div
                                class="payment_icon_card p-1">
                                <img
                                  src={visa}
                                  width="100%"
                                  alt />
                              </div>
                            </div>
                            <p
                              class="mb-1 mt-4 text-start">Credit
                              card ending in 1111</p>
                            <p
                              class="m-0 text-start">Expires
                              on : 04/2024</p>
                          </div>
                          <button
                            class="btn btn-outline-primary disable w-auto mt-4 py-2">Default</button>
                        </div>
                      </div>



                      <div
                        class="w-100 p-lg-4 p-md-4  mb-3 p-4 payment_card mt-4">
                        <div
                          class="d-flex align-items-start flex-column justify-content-start">
                          <div class="card_title pb-3">
                            <div
                              class="d-flex align-items-start flex-column gap-3">
                              <h6
                                style={{ fontWeight: '700' }}
                                class=" mb-0">Rheil Maadsen</h6>
                              <div
                                class="payment_icon_card p-1">
                                <img
                                  src={master_card}
                                  width="100%"
                                  alt />
                              </div>
                            </div>
                            <p
                              class="mb-1 mt-4 text-start">Credit
                              card ending in 1111</p>
                            <p
                              class="m-0 text-start">Expires
                              on : 04/2024</p>
                          </div>
                          <button
                            class="btn btn-outline-primary w-auto mt-4 py-2" style={{ minWidth: '160px' }}>Set as default</button>
                          <button
                            class="btn btn-outline-primary w-auto mt-3 py-2" style={{ minWidth: '160px' }}>Remove card  </button>
                        </div>
                      </div>


                      <button
                        class="btn btn-primary  py-3 px-4 mt-4">Add
                        payment method</button>
                    </div>
                  )}
                  {activeSection === 3 && (
                    <div class="profile_card_innner py-4 h-100">
                      <h3 class="mb-4 pb-3">
                        <img src={right_arrow} className="me-1" onClick={() => setActiveSection(null)} alt="" />Your orders</h3>
                      <div class="order_table w-100 overflow-auto">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Description</th>
                              <th>qty.</th>
                              <th>Amount</th>
                              <th className="text-nowrap">payment mode</th>
                              <th>Invoice</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>01/18/2024</td>
                              <td
                                class="text-start" style={{ minWidth: '220px' }}>ScentAware
                                8-item Smell Test
                                and Training
                                kit</td>
                              <td>1</td>
                              <td>$30.00</td>
                              <td>Credit card</td>
                              <td>
                                <u>0057ASEW</u>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  )}
                  {activeSection === 4 && (
                    <div class="profile_card_innner py-4 h-100">
                      <h3 class="mb-4 pb-3">
                        <img src={right_arrow} className="me-1" onClick={() => setActiveSection(null)} alt="" />Security settings</h3>

                      <div class="profile_card_innner h-100">
                        <div
                          class="d-flex align-items-center">
                          <label
                            class="m-0 d-flex align-items-center gap-3"
                            style={{ fontWeight: '700' }}>
                            Enable
                            Two-Factor Authentication (2FA)
                            <div class="checkbox-wrapper-2 d-flex align-items-center">
                              <input type="checkbox"
                                class="authenticate_check"
                                checked={isCheckboxChecked}
                                onChange={(e) => setIsCheckboxChecked(e.target.checked)} />
                            </div></label>
                        </div>
                        <div class="password_remember profile_mobile_list mt-4 pt-3">
                          <ul>
                            <li>Two-Factor Authentication
                              (2FA) adds an extra security
                              step to your login process.
                            </li>
                            <li>
                              After entering your
                              password, you'll also need
                              to verify your identity
                              using a second<br /> code
                              sent on
                              your phone or e-mail. </li>
                            <li>
                              This makes it much harder
                              for unauthorized users to
                              access your account, even if
                              they<br /> know your
                              password,
                              providing an essential layer
                              of security against data
                              breaches<br /> and identity
                              theft.</li>
                          </ul>
                        </div>

                        <div
                          class="d-flex flex-column align-items-start pb-3 disable mt-4 pt-3">
                          <label class="mb-2">E-mail :</label>
                          <input type="mail" name
                            placeholder="maadsen@outlook.com"
                            disabled={!isCheckboxChecked}
                            id />
                        </div>
                        <button class="btn btn-outline-primary d-block w-100 disable  py-3 px-4 mt-4" disabled={!isCheckboxChecked}>Change
                          e-mail address</button>
                      </div>
                    </div>
                  )}


                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <span class="loader"></span> */}

      <Footer />
    </body>
  );
};

export default UserProfile;
