import React from 'react'
import './ProductVideo.css'
import Product from '../../assets/video/product.mp4'
function ProductVideo() {
  return (
    <div className='ProductVideo'>
      <iframe   src="https://www.youtube.com/embed/Tnkg3PgGLtA?si=woJdUbplksr8lFac" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        {/* <video controls>
            <source src="https://www.youtube.com/embed/Tnkg3PgGLtA?si=woJdUbplksr8lFac" type='video/mp4' />
        </video> */}
    </div>
  )
}

export default ProductVideo
