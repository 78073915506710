import "./RenAwareUrine.css";
import img from "../../assets/images/renAware.png";
import { useState } from "react";
import ContactModal from "../ContactModal/ContactModal";

function RenAwareUrine() {
  const [show, setShow] = useState(false);
  
  return (
    <>
      <div className="RenAwareUrine">
        <div className="RenAwareUrine_left">
          <div className="heading">RenAware Urine Test strips</div>
          <p>
            Easy to use color-changing strips for 12 biomarkers. Dip the
            test-strip in a cup with the urine or urinate on the test-strip.
            Color changes occur within 90 seconds.{" "}
          </p>
          <button onClick={() => setShow(true)}>Contact Us</button>
        </div>
        <div className="RenAwareUrine_right h-100">
          <img src={img} alt="img" />
        </div>
      </div>
      <ContactModal show={show} setShow={setShow} />
    </>
  );
}

export default RenAwareUrine;
