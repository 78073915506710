import React, { useState } from "react";
import "./MainBanner.css";
import video from "../../assets/video/brain.mp4";

function MainBanner() {
  return (
    <>
      <div className="MainBanner">
        <video autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
        </video>

        <div className="MainBannerContent">
          <span>Your Senses Know Before You Do</span>
          <p>
            pioneering accessible digital tools to detect changes in health as
            early as possible—partnering with researchers and providing
            individuals with reliable tools to measure olfactory loss and track
            their brain health.
          </p>
        </div>
      </div>
    </>
  );
}

export default MainBanner;
