import React, { useState } from 'react'
import styled from 'styled-components'
import Hero_blue from '../assets/Hero_blue.png'
import resetPasswordLink from '../assets/resetPasswordLink.svg';
import pattern1 from '../assets/Pattern1.svg';
import pattern2 from '../assets/pattern2.svg';
import Axios from 'axios';
import Swal from 'sweetalert2';
import outline from "../assets/outline.svg";
// import Confirmation from './Login Pages/Confirmation'
// import Navbar from './Navbar'
import Header from './Reuseable Components/Header';
import { useNavigate, useLocation, Link } from "react-router-dom";
import logoBlack from "../assets/logo-black.png";
import forgot_img from "../assets/verification_code.png";
import forgot_mobile from "../assets/verification_code_mobile.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isMobile } from "react-device-detect";
import Modal from 'react-modal';
import SuccessDialog from './Modal/SuccessDialog';
import config from '../config';
import { StaticHeader } from './StaticHeader/StaticHeader';
import Footer from './Footer/Footer';

const initialValues = {
  Number: "",
};


function DialogueConfirm() {
  const [Number, setNumber] = useState("");
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);

  const openSuccessModal = () => {
    setSuccessModalIsOpen(true);
  };

  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false);
  };

  const validationSchema = Yup.object({
    Number: Yup.string()
    .matches(/^\d{6}$/, "Please Enter valid verification code!") // Exactly 6 digits
    .required("Verification Code is required!"),
  });

  function handleClickSignIn(value) {
    setLoad(true);
    var stat;
    var obj = {
      "EmailAddress": "xaidi.zaidi.ali@gmail.com",
      "Url": "/confirmsignup",
      "UserId": 5,
      "ConfirmationCode": "881932"
    }

    obj["ConfirmationCode"] = value.Number.toString();
    var newuserid = parseInt(location.state.userid);
    obj["EmailAddress"] = location.state.email;
    obj["UserId"] = newuserid;

    let api = `${config.BASE_URL}`
    Axios.post(api, obj).then(res => {
      stat = res.data;
      if (stat["StatusCode"] === 0) {
        openSuccessModal()
        setTimeout(() => { 
          setSuccessModalIsOpen(false)
          navigate('/');
        }, 3000);
        var useridval = stat.Data.UserId;
        console.log("userid", useridval);
        setLoad(false);
        
      }
      else {
        Swal.fire({
          icon: 'warning',
          title: 'Confirmation Failed',
          text: "Invalid Code, Please Enter Again!",
          showCancelButton: false,
          confirmButtonText: 'Try Again!',
        });
        setLoad(false);
      }
    })
    //navigate('/Signup');
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleClickSignIn,
  });

  const Clickback = () => {
    navigate('/');

  }
  return (
    <Wrapper>
      <body className="renaware">
        <StaticHeader />
        <section className="login_page inner-banner-section sign_up_outer d-flex align-items-center">
          <div className="container">
            <div
              className="row flex-lg-row flex-md-column-reverse flex-column-reverse">
              <div
                className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center pb-lg-0 pb-md-0 pb-4">
                <div className="card_title w-100">
                  <h2 className="mb-lg-4 mb-md-3 mb-3">Enter verification code</h2>
                  <p className="text-start m-0">We've sent a verification code to your email. Check your inbox (and maybe your spam <br/>
                   folder, just in case). Once you've got the code, enter it here to confirm your account.</p>
                  <div className="mt-4 pt-lg-2 form_feild">
                    <form action>
                      <div className="row">
                        <div
                          className="col-lg-7 col-md-12 col-sm-12 col-12">
                          <label for className="w-100">Enter
                            verification code : </label>
                          <input type="text"
                            id="Number"
                            maxLength={6}
                            className={`form-control ${formik.touched.Number && formik.errors.Number
                              ? "is-invalid"
                              : ""
                              }`}
                            name="Number"
                            placeholder="Verification Number"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.Number}
                            isValid={formik.isValid}
                            isTouched={formik.touched.Number}
                            invalidFeedback={formik.errors.Number}
                            onInput={(e) => {
                              // e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 6);
                              const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 6); // Restrict to numbers only, max 6 digits
                              formik.setFieldValue("Number", value);
                            }} />
                          <div className="invalid-feedback">
                            {formik.errors.Number}
                          </div>
                        </div>
                        <div
                          className="col-lg-7 col-md-12 col-sm-12 col-12 mt-lg-4 mt-3">
                          <button
                            onClick={formik.handleSubmit}
                            type='submit'
                            className="btn btn-primary mb-0">
                              {load ? (
                              <div className='w-100 align-items-center d-flex justify-content-center'>
                                <h1
                                  class="loader"
                                  disabled={load}
                                  style={{
                                    // color: "#101A36",
                                    margin: "-5px",
                                    width: '30px',
                                    height: '30px',
                                  }}
                                ></h1>
                              </div>
                            ) : (
                              "Submit"
                            )}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-0 mb-lg-0 pb-lg-0">
                <div
                  className="forgot_img d-flex align-items-center justify-content-center">
                  <img src={forgot_img}
                    className="d-lg-block d-md-none d-none" alt />
                  <img src={forgot_mobile}
                    className="d-lg-none d-md-block d-block w-auto" alt />
                </div>
              </div>
            </div>
          </div>
          
        </section>
        <Footer />
      </body>
      <Modal
        isOpen={successModalIsOpen}
        onRequestClose={closeSuccessModal}
        contentLabel="Example Modal"
        className='login_modal'
      >
        <SuccessDialog/>
      </Modal>

      {/* <section class="thankyou-section" id="shop">
        <div class="container-fluid p-0 ">
          <div>
            <div className="card">
              <div className="col col-sm-1">
                
              </div>
              <div className="row text-center">
                <div className="col left-container">
                  <section className="col-md-6 new">
                    <img src={resetPasswordLink} alt="Cart_icon" className="img-here" />
                  </section>

                  <section className="col-md-6 new">
                    <img
                      src={pattern2}
                      alt="Cart_icon"
                      className="img-Pattern2"
                    />
                  </section>
                </div>

                <div className="col">
                  <div className="title">
                    <h4> Enter Confirmation Code</h4>
                  </div>

                  <div className="discription">
                  We have sent a email confirmation code you your email,
              Enter the code below and begin your sensify experience today!
                  </div>

                  <div class="row email-section">
                    <div className="col col-md-12 col-sm-12 col-xs-12 ">
                      <label for="Email" class="label-align word">
                        Code :
                      </label>
                    </div>
                    <div className="col col-md-12 col-sm-12 col-xs-12">
                    <input
                  type="text"
                  name="Code"
                  placeholder=""
                  value={Number}
                  onChange={(e) => setNumber(e.target.value)}

                />
                    </div>
                  </div>

                  <div class="alignment-order">
                    <div class="col continue">
                    <button onClick={handleClickSignIn} type="submit" value="Submit">
                  <a style={{ textDecoration: "none", color: "white", textAlign: "center" }}>
                    Next
                  </a>
                </button>
                      {isMobile ? (
                        <div>
                          {load ? (
                            <h1
                              className={load ? "loading-spinner" : "LoginBtn"}
                              disabled={load}
                              style={{
                                color: "blue",
                                margin: "10px 0 150px 80px",
                              }}
                            ></h1>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div>
                          {load ? (
                            <h1
                              className={load ? "loading-spinner" : "LoginBtn"}
                              disabled={load}
                              style={{
                                color: "blue",
                                margin: "10px 0 150px 140px",
                              }}
                            ></h1>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <section className="col-md-6 new">
                    <img
                      src={pattern1}
                      alt="Cart_icon"
                      className="img-Pattern1"
                    />
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}


    </Wrapper>
  )
}

const Wrapper = styled.div`
.card {
  // position: absolute;
  width: 968px;
  height: 580px;
  left: 0;
  // top: 151px;

  background: #ffffff;
  box-shadow: 0px 4px 32px 16px rgba(30, 30, 30, 0.25);
  border-radius: 8px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
}
.container-fluid {
  width: 63%;
}
.btn-close {
  margin-left: 10px;
  margin-top: 10px;
}

.thankyou-section {
  padding-top: 150px;
}

.title {
  // position: absolute;
  height: 28px;
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: start;
  color: #4e4e4e;
  margin-top:50px;
}
input[type="number"] {
  box-sizing: border-box;
  width: 320px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #26649d;
  border-radius: 8px;
  margin-bottom: 40px;
}

.discription {
  // position: absolute;
  width: 307px;
  height: 76px;
  // left: 530px;
  // top: 100px;

  font-family: "WorkSans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #4e4e4e;
  text-align: start;
}

.label-align {
  width: 178px;
  height: 19px;
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4e4e4e;
}

input[type="email"] {
  box-sizing: border-box;
  //    position: absolute;
  width: 320px;
  height: 48px;
  // left: 510px;
  // top: 375px;
  background: #ffffff;
  border: 1px solid #26649d;
  border-radius: 8px;

  margin-bottom: 40px;
}

.alignment-order {
  // position: absolute;
  width: 320px;
  height: 48px;
  // left: 545px;
  // top: 350px;

  filter: drop-shadow(0px 16px 24px rgba(38, 100, 157, 0.3));
}
.continue {
  // margin-top: 10px;
  // margin-left: 20px;
}
.continue button {
  background-color: #22669c;
  /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */

  border: none;
  padding: 10px 0px;
  color: white;
  width: 100%;
}

.img-here {
  position: absolute;
  width: 370px;
  height: 300px;
  left: 80px;
  top: 100px;
}

.img-Pattern2 {
  position: absolute;
  width: 165px;
  height: 150px;
  left: 2px;
  top: 450px;

  mix-blend-mode: normal;
  border-radius: 8px 0px 0px 8px;
  transform: rotate(0deg);
}

.img-Pattern1 {
  position: absolute;
  width: 480px;
  height: 148px;
  left: 470px;
  top: 424px;
  mix-blend-mode: normal;
  opacity: 0.5;
  border-radius: 8px 0px 0px 8px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.email-section {
  text-align: start;
}
@media Screen and (min-width: 996px) and (max-width: 1150px) {
  .container-fluid {
    width: 75%;
  }
  .card {
    width: auto;
  }
  .img-here {
    width: 320px;
    height: 200px;
  }
  .img-Pattern1 {
    width: 372px;
    height: 148px;
    left: 480px;
    top: 440px;
  }
}

@media Screen and (min-width: 320px) and (max-width: 995px) {
  .text-center {
    text-align: center;
  }
  .left-container {
    display: none;
  }

  .card {
    // position: absolute;
    width: auto;
    height: auto;
    left: auto;
    box-shadow: unset;
    border: none;
  }
  .new {
    display: none;
  }
  input[type="email"] {
    box-sizing: border-box;
    //    position: absolute;
    width: 290px;
    height: 48px;
    left: 0px;
    top: 375px;
    background: #ffffff;
    border: 1px solid #26649d;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .title {
    //   width: 307px;
    width: auto;
    height: 28px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: "WorkSans";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #4e4e4e;
  }
  .discription {
    width: 307px;
    //   width: auto;

    font-family: "WorkSans";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 19px;

    color: #4e4e4e;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .label-align {
    //   width: 178px;
    text-align: start;
    width: 290px;
    height: 19px;
    margin-top: 0px;
    font-family: "WorkSans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: #4e4e4e;
  }

  .alignment-order {
    //   position: absolute;
    //   width: 306px;
    //   height: 48px;
    //   left: 75px;
    //   top: 350px;
    width: auto;

    filter: drop-shadow(0px 16px 24px rgba(38, 100, 157, 0.3));
  }

  .continue {
    //   margin-top: 10px;
    //   position: absolute;
    margin-left: 0px;
  }
  .continue button {
    background-color: #22669c;
    /* box-shadow: 0px 8px 16px rgba(30, 30, 30, 0.3); */

    border: none;
    padding: 10px 0px;
    color: white;
    width: 290px;
  }

  .thankyou-section {
    padding-top: 100px;
  }
  .email-section {
    text-align: center;
  }
}

`

export default DialogueConfirm