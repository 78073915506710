import React from "react";
import AnnulPlan from "../../components/AnnulPlan/AnnulPlan";
import ProductForResearchersBanner from "../../components/ProductForResearchersBanner/ProductForResearchersBanner";
import ScentAwareProducts from "../../components/ScentAwareProducts/ScentAwareProducts";
import ScentAwareProProducts from "../../components/ScentAwareProProducts/ScentAwareProProducts";
import RenAwareUrine from "../../components/RenAwareUrine/RenAwareUrine";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function ProductForResearchers() {
  return (
    <>
      <Header />
      <ProductForResearchersBanner />
      <AnnulPlan />
      <div id="Scentaware16">
        <ScentAwareProducts />
      </div>
      <div id="Scentawarepro">
        <ScentAwareProProducts />
      </div>
      <RenAwareUrine />
      <Footer />
    </>
  );
}

export default ProductForResearchers;
