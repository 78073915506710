import "./Contact.css";
import address from "../../assets/images/address.png";
import call from "../../assets/images/call.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { message } from "antd";
import { useState } from "react";

const initialValues = {
  name: "",
  organization: "",
  email: "",
  message: "",
};

function Contact() {
  const [load, setLoad] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required!"),
    organization: Yup.string().required("Organization is required!"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
    message: Yup.string().required("Message is required!"),
  });
  const handleSubmit = async (values) => {
    setLoad(true);
    try {
      const data = {
        Name: values.name,
        Email: values.email,
        Organisation: values.organization,
        Message: values.message,
      };
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        "https://gokepiqof3.execute-api.us-east-1.amazonaws.com/prod/api/admin/contact-form",
        data,
        { headers }
      );
      message.open({
        type: "success",
        content: "Thank you for submitting Query",
        style: {
          marginTop: "150px",
        },
      });
      formik.resetForm();
      setLoad(false);
    } catch (error) {
      console.log("error", error);
      setLoad(false);
    } finally {
      setLoad(false);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return (
    <div className="Contact">
      <div className="Contact_left">
        <div className="heading">Let’s get in touch</div>
        <div className="details">
          <div className="detail">
            <img src={address} alt="address" />
            <span>
              251 E 32nd St. 7C <br />
              NY, 10016
            </span>
          </div>
          <div className="detail">
            <img src={call} alt="call" />
            <span>857 272 5666 | 646 285 8736</span>
          </div>
        </div>
      </div>
      <form className="Contact_right">
        <div className="w-100">
          <input
            type="text"
            id="fullName"
            placeholder="Your Name"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            name="name"
            className={`form-control ${
              formik.touched.name && formik.errors.name ? "is-invalid" : ""
            }`}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            isValid={formik.isValid}
            isTouched={formik.touched.name}
            invalidFeedback={formik.errors.name}
          />
          <div className="invalid-feedback">{formik.errors.name}</div>
        </div>
        <div className="w-100">
          <input
            type="text"
            id="Enter your organization"
            placeholder="Organization"
            className={`form-control ${
              formik.touched.organization && formik.errors.organization
                ? "is-invalid"
                : ""
            }`}
            name="organization"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.organization}
            isValid={formik.isValid}
            isTouched={formik.touched.organization}
            invalidFeedback={formik.errors.organization}
          />
          <div className="invalid-feedback">{formik.errors.organization}</div>
        </div>
        <div className="w-100">
          <input
            type="text"
            id="email"
            placeholder="Email"
            className={`form-control ${
              formik.touched.email && formik.errors.email ? "is-invalid" : ""
            }`}
            name="email"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            isValid={formik.isValid}
            isTouched={formik.touched.email}
            invalidFeedback={formik.errors.email}
          />
          <div className="invalid-feedback">{formik.errors.email}</div>
        </div>
        <div className="w-100">
          <textarea
            id="Description"
            name="message"
            placeholder="Your message"
            className={`form-control ${
              formik.touched.message && formik.errors.message
                ? "is-invalid"
                : ""
            }`}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.message}
            isValid={formik.isValid}
            isTouched={formik.touched.message}
            invalidFeedback={formik.errors.message}
          ></textarea>
          <div className="invalid-feedback">{formik.errors.message}</div>
        </div>
        <button
          className="d-flex flex-row justify-content-center"
          onClick={formik.handleSubmit}
        >
          {load ? (
            <div className="align-items-center d-flex justify-content-center">
              <h1
                class="loader"
                disabled={load}
                style={{
                  // color: "#101A36",
                  margin: "-5px",
                  width: "30px",
                  height: "30px",
                }}
              ></h1>
            </div>
          ) : (
            ""
          )}
          <span>Submit</span>
        </button>
      </form>
    </div>
  );
}

export default Contact;
