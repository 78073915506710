import React from 'react'
import './ResearchPartner.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import partnerImg1 from '../../assets/images/researchPartner1.png'
import partnerImg2 from '../../assets/images/researchPartner2.png'
import partnerImg3 from '../../assets/images/researchPartner3.png'
import partnerImg4 from '../../assets/images/researchPartner4.png'
import partnerImg5 from '../../assets/images/researchPartner5.png'
import partnerImg6 from '../../assets/images/researchPartner6.png'
import arrowLeft from '../../assets/images/arrow-left.svg'
import arrowRight from '../../assets/images/arrow-right.svg'
function ResearchPartner() {
    let images = [partnerImg1, partnerImg2, partnerImg3, partnerImg4, partnerImg5, partnerImg6]
    return (
        <div className='ResearchPartner'>
            <div className='ResearchPartnerSlideContainer d-md-flex d-none'>
                <button className='custom-prev'><img src={arrowLeft} alt="arrowLeft" /></button>
                <button className='custom-next'><img src={arrowRight} alt="arrowRight" /></button>
                <Swiper
                    spaceBetween={10}
                    loop={true}
                    navigation={{
                        prevEl: '.custom-prev',
                        nextEl: '.custom-next'
                    }}
                    freeMode={true}
                    modules={[Navigation]}
                    breakpoints={{
                        1700: {   
                            slidesPerView: 5, 
                            spaceBetween: 10,
                        },
                        1330: {   
                            slidesPerView: 4, 
                            spaceBetween: 10,
                        },
                        1: {   
                            slidesPerView: 3, 
                            spaceBetween: 10,
                        }
                    }}
                >
                    {
                        images.map((img, i) => {
                            return <SwiperSlide key={i} className='slide' >
                            <img src={img} alt={`partnerImg`} className='slideImage' />
                        </SwiperSlide>
                        })
                    }
                </Swiper>
            </div>
            <div className='row m-0 d-md-none d-flex'>
                {
                    images.map((img, i) => {
                        return <div key={i} className='col-6 flex-img-container' >
                            {/* <div className='flex-img' style={{ background: `url('${img}')`}}></div> */}
                            <img src={img} alt="" className='flex-img'/>
                        </div>
                    })
                }
            </div>
        </div>

    )
}

export default ResearchPartner
