import React, { useEffect, useState } from "react";
import "./StaticHeader.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { Offcanvas } from "react-bootstrap";
import menuIcon from "../../assets/images/menu.svg";
export const StaticHeader = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setTimeout(() => {
      setShow(false);
    }, 0);
  };
  const handleShow = () => setShow(true);
  const location = useLocation();
  var socilaLogIn = localStorage.getItem("socilaLogIn");
  const navigate = useNavigate();
  const [userName, setUserName] = useState([]);
  const [mobileNavbarToggle, setMobileNavbarToggle] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const userDataByAccounts = JSON.parse(localStorage.getItem("socialObj"));
    const userDataByEmail = JSON.parse(localStorage.getItem("UserData"));
    if (userDataByAccounts) {
      const Name =
        userDataByAccounts.FirstName + " " + userDataByAccounts.LastName;
      setUserName(Name);
    } else if (userDataByEmail) {
      setUserName(userDataByEmail.Data.UserName);
    } else {
      setUserName(null);
    }
  }, []);

  const handleclicksign = () => {
    var stri = localStorage.getItem("local_id_store");
    setMobileNavbarToggle(true);
    stri = parseInt(stri);
    if (stri > 0 || socilaLogIn === "true") {
      navigate("/userProfile");
    } else {
      navigate("/Signin");
    }
  };
  const handleRedirectToHome = () => {
    navigate("/");
  };

  const handleSignInSuccess = (newUserName) => {
    setUserName(newUserName);
  };
  return (
    <>
      <div className="staticHeader">
        <Link to="/">
          <div className="MainLogoContainer">
            <img src={logo} alt="logo" />
            <span>SensifyLife</span>
          </div>
        </Link>
        <div className="rightContent d-lg-flex d-none">
          {userName ? (
            <button className="login" onClick={handleclicksign}>
              {userName}
            </button>
          ) : null}
        </div>
        <div className="d-lg-none d-flex">
          <img
            src={menuIcon}
            alt="menuIcon"
            className="cursor-pointer"
            onClick={handleShow}
          />
        </div>
      </div>
      <Offcanvas
        className="responsive-sidebar"
        show={show}
        onHide={handleClose}
        onClick={handleClose}
      >
        <Offcanvas.Header>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Link to="/">
              <div className="MainLogoContainer">
                <img src={logo} alt="logo" />
                <span>SensifyLife</span>
              </div>
            </Link>
            <button className="dark-close-btn" onClick={handleClose}>
              &times;
            </button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="menu">
            <ul>
              <NavLink to={"/#about"}>
                <li>About</li>
              </NavLink>
              <NavLink to={"/#smellLoss"}>
                <li>Understanding Smell Loss</li>
              </NavLink>
              <NavLink to={"/#products"}>
                <li>Products</li>
              </NavLink>
              <NavLink to={"/#individualsandresearchers"}>
                <li>For Individuals and Researchers</li>
              </NavLink>
              <li className="rightContent">
                {userName ? (
                  <button className="login" onClick={handleclicksign}>
                    {userName}
                  </button>
                ) : (
                  <button className="login" onClick={openModal}>
                    Login
                  </button>
                )}
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
