import { Accordion } from 'react-bootstrap';
import './Comprehensive.css'
import { useState } from 'react';

function Comprehensive() {
    let data = [
        {
            color: "#E88280",
            title: "Blood",
            description: "Blood in urine may be due to a problem in your kidneys or parts of the urinary tract."
        },
        {
            color: "#ECBC3C",
            title: "Ketone",
            description: "Ketones accumulate when the body uses fats for energy due to a lack of sugars or carbs."
        },
        {
            color: "#999500",
            title: "Creatinine",
            description: "Creatinine, a waste product from exercise and meat consumption, is filtered by healthy kidneys and excreted in urine, serving as a marker of kidney health."
        },
        {
            color: "#03432B",
            title: "Specific Gravity",
            description: "Specific Gravity measure of the concentration of solutes in the urine and could be indicative of dehydration."
        },
        {
            color: "#7B4F9A",
            title: "Ascorbate",
            description: "Ascorbic acid in urine may be a result of consuming fruits and veggies. Normally ascorbate levels are low in urine, but its presence may interfere with urinalysis."
        },
        {
            color: "#DCE451",
            title: "Glucose",
            description: "Normally, urine contains very little or no glucose. High urine glucose means high blood glucose and could be indicative of diabetes."
        },
        {
            color: "#7CB68E",
            title: "Protein",
            description: "A high protein level in urine indicates kidney damage."
        },
        {
            color: "#FAA555",
            title: "pH",
            description: "Some types of kidney stones or bacterial infection may lead to  alkaline or acidic urine."
        },
        {
            color: "#9C0E87",
            title: "Nitrite",
            description: "Nitrites in urine may be a sign of a urinary tract infection."
        },
        {
            color: "#9976A2",
            title: "Leukocytes",
            description: "Leukocytes in urine may be a sign of a urinary tract infection"
        },
        {
            color: "#F5E6A3",
            title: "Bilirubin",
            description: "Elevated bilirubin levels, resulting from the breakdown of red blood cells, can signal liver or bile duct issues."
        },
        {
            color: "#FCC79D",
            title: "Urobilinogen",
            description: "Excess urobilinogen in urine may indicate liver diseases."
        }
    ]

    const [activeKeys, setActiveKeys] = useState([]);

    const handleSelect = (eventKey) => {
        setActiveKeys((prevKeys) =>
        prevKeys.includes(eventKey)
            ? prevKeys.filter((key) => key !== eventKey)
            : [...prevKeys, eventKey]
        );
    };
  return (
    <>
        <div className="ComprehensiveContainer">
            <div className="Comprehensive">
                <div className='heading'>Get comprehensive insights with 12 biomarkers</div>
                <div className='row m-0 ComprehensiveCards w-100 d-md-flex d-none'>
                    {
                        data.map((element, index) => {
                            return <div className='col-6 col-md-4 col-lg-3 p-0' key={index}>
                            <div className='ComprehensiveCard'>
                                <div className='ComprehensiveCard_box' style={{ background: element.color}}> </div>
                                <div className='detail'>
                                    <div className='title'>{element.title}</div>
                                    <p>{element.description}</p>
                                </div>
                            </div>
                        </div>
                        })
                    }
                </div>

                <div className='d-md-none d-block w-100'>
                <Accordion activeKey={activeKeys} onSelect={handleSelect}>
                    {data.map((element, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>
                            <div className="accordionHeader">
                            <div
                                className="ComprehensiveCard_box"
                                style={{ background: element.color }}
                            ></div>
                            <span>{element.title}</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                        {element.description}
                        </Accordion.Body>
                        </Accordion.Item>
                    ))}
                    </Accordion>
                    </div>
            </div>
        </div>
        <div className='Comprehensive_bottomContent'>
            <div className='row m-0'>
                <div className='col-12 col-lg-6 d-flex align-items-center'>
                    <div className='heading'>
                    Memory, language, executive function, attention, visuospatial skills progressively decline in neurodegenerative diseases.
                    </div>
                </div>
                <div className='col-12 col-lg-6 d-flex align-items-center'>
                    <p>Digital tools measure all major cognitive domains effectively.</p>
                </div>
            </div>
        </div>
    </>
  );
}

export default Comprehensive;
