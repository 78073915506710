import React from 'react'
import './SmellLoss.css'
import virus from '../../assets/images/virus.png'
import Neurodegeneration from '../../assets/images/Neurodegeneration.svg'
import Nasal from '../../assets/images/nasal.svg'
import headInjury from '../../assets/images/headInjury.svg'
import age from '../../assets/images/age.svg'
import Medicin from '../../assets/images/Medicin.svg'
import smoking from '../../assets/images/smoking.svg'
import exposure from '../../assets/images/exposure.svg'

function SmellLoss() {

    let data = [
        {
            img: virus,
            heading: "Viral Infections",
            description: "Such as the common cold or flu."
        },
        {
            img: Neurodegeneration,
            heading: "Neurodegeneration",
            description: "Degeneration or inflammation in nervous system, impacting brain health."
        },
        {
            img: Nasal,
            heading: "Nasal and Sinus Issues",
            description: "Conditions like allergies, sinusitis, or nasal polyps."
        },
        {
            img: headInjury,
            heading: "Head Injuries",
            description: "Trauma to the head can damage the olfactory nerves."
        },
        {
            img: age,
            heading: "Age",
            description: "As we age, our sense of smell may naturally decline."
        },
        {
            img: Medicin,
            heading: "Medicine",
            description: "Certain medications can alter the sense of smell."
        },
        {
            img: smoking,
            heading: "Smoking",
            description: "Smoking or vaping can negatively impact your ability to smell."
        },
        {
            img: exposure,
            heading: "Exposure to toxic chemicals",
            description: "Occupational exposure to chemicals has been associated with smell loss."
        },
        {}
    ]
    return (
        <div className='SmellLoss'>
            <div className='topDetail'>
                {/* <small>Understanding Smell Loss</small> */}
                <h2>What If Losing Your Sense of Smell Was More Than Just a Cold?</h2>
                <p>Smell loss can occur due to various reasons. </p>
            </div>
            <div className='iconicContent row'>
                {
                    data.map((card, index) => {
                        return <div className='col-12 col-md-6 col-lg-4 iconicContentCard' key={index}>
                            <div>
                                <img src={card.img} alt={card.heading} />
                            </div>
                            <div className='detail'>
                                <p>{card.heading}</p>
                                <p>{card.description}</p>
                            </div>
                        </div>
                    })
                }
            </div>

            <div className='bottomContent'>

                <div className='smellLossDetail'>
                    <span>Smell loss is measurable & modifiable</span>
                    <p>Chronic rhinosinusitis with nasal polyps causes symptoms like sinus pain and loss of smell, affecting quality of life and sleep. In severe cases, treatment can help improve the sense of smell effectively and quickly.</p>
                </div>
                <div className='smellLossDetailImg'> 
                    <div className='img'></div>
                </div>
            </div>
        </div>
    )
}

export default SmellLoss
