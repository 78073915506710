import React from 'react'
import './TestYourSense.css'
import arrowRight from '../../assets/images/arrow-right.svg'

function TestYourSense() {
    return (
        <div className="TestYourSenseContainer">
            <div className='TestYourSense'>
                <h2>Test your sense of smell in 10 minutes!</h2>
                <div className='steps'>
                    <div className='stepCard'>
                        <small>Step 1</small>
                        <h5>Scan any tube from the box.</h5>
                        <p>Pick a tube from the foam tray. In the SensifyAware app, choose ScentAware test and scan the QR code on the tube, when directed.</p>
                    </div>
                    <div className='arrowStep'>
                        <img src={arrowRight} alt="arrowRight" />
                    </div>
                    <div className='stepCard'>
                        <small>Step 2</small>
                        <h5>Open the cap, smell the tube.</h5>
                        <p>Open the cap and gently sniff by holding the inhaler tube near your nostrils. Try to identify the scent.</p>
                    </div>
                    <div className='arrowStep'>
                        <img src={arrowRight} alt="arrowRight" />
                    </div>
                    <div className='stepCard'>
                        <small>Step 3</small>
                        <h5>Identify the odor and respond.</h5>
                        <p>Identify the correct answer among the options displayed on your mobile screen. Repeat for all the tubes in the box</p>
                    </div>
                    <div className='arrowStep'>
                        <img src={arrowRight} alt="arrowRight" />
                    </div>
                    <div className='stepCard'>
                        <small>Step 4</small>
                        <h5>Check your result.</h5>
                        <p>After you’ve tested all the tubes, a result will be displayed on your device. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestYourSense
