import React from "react";
import "./LateDiagnosis.css";

function LateDiagnosis() {
  return (
    <div className="LateDiagnosis">
      <div className="row position-relative w-100">
        <div className="col-12 col-xl-6 LateDiagnosisContent">
          <span>
            The Problem: <br /> Late Diagnosis is Too Late
          </span>
          <p>
            By the time Alzheimer’s or Parkinson’s symptoms appear, the brain
            has already suffered significant damage. <br /> Current diagnostic methods
            focus on cognitive symptoms and motor impairments—often missing the
            earliest warning signs.
          </p>
        </div>
        <div className="col-12 col-xl-6 d-xl-flex d-none LateDiagnosisImg">
          <div className="part1"></div>
          <div className="part2">
            <div className="img"></div>
          </div>
          <div className="part3"></div>
        </div>
      </div>
    </div>
  );
}

export default LateDiagnosis;
