import React from 'react'
import './PathOfSmell.css';

function PathOfSmell() {
    return (
        <div className="PathOfSmell">
            <div className="PathOfSmellBanner"></div>
            <div className="PathOfSmellContent">
                <h2>The Path of Smell: <br /> From Nose to the Brain</h2>
                <p>Olfactory neurons are the only exposed neurons. Odor molecules enter the nose, bind to receptors in the nasal cavity, trigger signals in the olfactory neurons that travel through the olfactory bulb to the brain’s smell processing regions.</p>
                <div className="PathOfSmellTypesOfSmell">
                    <h6>Types of Smell Loss</h6>
                    <div className="content">
                        <div className="contentInside">
                            <div className="title">Anosmia</div>
                            <p className='p'>Complete loss of ability to smell.</p>
                        </div>
                        <div className="contentInside">
                            <div className="title">Parosmia</div>
                            <p className='p'>Distorted odor perception 
                            (triggered by a stimulus).</p>
                        </div>
                    </div>
                    <div className="content">
                        <div className="contentInside">
                            <div className="title">Hyposmia</div>
                            <p className='p'>Refers to a partial loss of the ability to smell.</p>
                        </div>
                        <div className="contentInside">
                            <div className="title">Phantosmia</div>
                            <p className='p'>Olfactory hallucination 
                            (occur without a stimulus).</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PathOfSmell
