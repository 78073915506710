import { Fragment } from "react"
import './IndividualsBanner.css';
import chef from '../../assets/images/chef.svg'
import chef1 from '../../assets/images/chef1.svg'
import chef2 from '../../assets/images/chef2.svg'
import chef3 from '../../assets/images/chef3.svg'

const IndividualsBanner = () => {
    return <Fragment>
        <div className="InidividualsBanner">
            <div className="IndividualsBannerCorner">
                <div className="individualsBannerInsideText">
                    <h1>The crucial role of smell in our daily lives.</h1>
                    <p>A well-functioning olfactory system is not just about quality of life; it's about staying alert to the dangers that one cannot see or hear but can only be sensed through smell.</p>
                </div>

                <div className="individualsBannerInsideCardContainer">
                    <div className="individualsBannerInsideCard">
                        <div><div className="icon"> <img src={chef} alt="chef"></img></div></div>
                        <p>Chefs and sommeliers rely on their sense of smell for flavor profiling and food safety.</p>
                    </div>
                    <div className="individualsBannerInsideCard">
                        <div><div className="icon"> <img src={chef1} alt="chef1"></img></div></div>
                        <p>Detecting smoke and hazardous chemicals is vital for timely response and evacuation.</p>
                    </div>
                    <div className="individualsBannerInsideCard">
                        <div><div className="icon"> <img src={chef2} alt="chef2"></img></div></div>
                        <p>Recognizing gas leaks or chemical spills can prevent accidents and save lives.</p>
                    </div>
                    <div className="individualsBannerInsideCard">
                        <div><div className="icon"> <img src={chef3} alt="chef3"></img></div></div>
                        <p>For soldiers and law enforcement, a keen sense of smell is vital for safety and mission success.</p>
                    </div>
                </div>
            </div>

        </div>
    </Fragment>
}

export default IndividualsBanner